import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "react-datepicker/dist/react-datepicker.css"
import * as Sentry from '@sentry/react';
import {CaptureConsole, HttpClient} from "@sentry/integrations";
import {MenuDataProvider} from './contexts/MenuDataContext';

// load .env.development.local
require('dotenv').config();

Sentry.init({
    dsn: "https://396cf3022c8f0c135cad9ea5503c3f83@o72372.ingest.sentry.io/4506002381996032",
    release: 'opnij-frontend@1.0.0',
    environment: process.env.REACT_APP_ENVIRONMENT_TYPE === 'test' ? "test" : process.env.REACT_APP_ENVIRONMENT_TYPE === 'production' ? "production" : "development",
    enabled: process.env.REACT_APP_ENVIRONMENT_TYPE !== 'dev',
    attachStacktrace: true,
    integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay(),
        new CaptureConsole({
            levels: ["info", "error", "debug", "assert"],
        }),
        new HttpClient(),
    ],
    sendDefaultPii: true, // Capture headers and cookies (for HttpClient)
    tracesSampleRate: 0.1, // Capture 10% of the transactions (for BrowserTracing/Performance Monitoring)
    replaysSessionSampleRate: 0.1, // Set the sample rate to 10% (for Session Replay)
    replaysOnErrorSampleRate: 1.0, // Set the sample rate to 100% when sampling sessions where errors occur (for Session Replay)
});

ReactDOM.render(
  <React.StrictMode>
      <MenuDataProvider>
          <App />
      </MenuDataProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
