import {Controller, useForm} from "react-hook-form";
import DatePicker, {registerLocale} from "react-datepicker";
import calenderLogo from "../../resources/images/calendarPurple.svg";
import ActionButton from "../../components/ActionButton";
import checkLogo from "../../resources/images/checkGreen.svg";
import styled from "styled-components";
import {black, Card, CardTitle, nunito, red, sourceSansPro} from "../../Theme";
import React, {useEffect, useState} from "react";
import nl from "date-fns/locale/nl";
import Api from "../../util/Api";
import AppStorage, {StorageKey} from "../../util/AppStorage";
import {useHistory} from "react-router-dom";
import {isInReadOnlyMode} from "../../App";
import Dropdown from "../../popups/Dropdown";
import {getDay} from "date-fns";
import showBasicPopup from "../../popups/showBasicPopup";

function ExamCard({exam, hideOnMobile, onExamChange, date, match, ...props}) {
    registerLocale("nl-NL", nl);
    const history = useHistory();
    const {register, control, handleSubmit, setValue, formState: {errors}} = useForm();
    const isMakingNewExam = !match?.params?.id
    const [isLoading, setIsLoading] = useState(false);

    const examIsFromExternalDatasource = exam && exam !== {} && exam.externalId

    useEffect(() => {
        setValuesBasedOnExam()
        // eslint-disable-next-line
    }, [exam])

    const isWeekday = (date) => {
        const day = getDay(date);
        return day !== 0 && day !== 6;
    };

    const colorOptions = [
        {
            value: "#FFFFFF",
            label: "Wit"
        },
        {
            value: "#EFEFEF",
            label: "Grijs"
        },
        {
            value: "#FFB3B3",
            label: "Rood"
        },
        {
            value: "#FAD9A7",
            label: "Oranje"
        },
        {
            value: "#FFEEB9",
            label: "Geel"
        },
        {
            value: "#CDF5C2",
            label: "Groen"
        },
       {
            value: "#CEFCFC",
            label: "Turquoise"
        },
        {
            value: "#C5DBFD",
            label: "Blauw"
        },
        {
            value: "#D4BAF5",
            label: "Paars"
        },
        {
            value: "#F8CCFF",
            label: "Roze"
        }
    ]

    return <Card {...props}>
        <CardTitle>Algemeen</CardTitle>
        <FieldList>
            <Field>
                <FieldTitle>Vak</FieldTitle>
                <FieldInput type="text" {...register("subject", {required: true})} disabled={isInReadOnlyMode() || examIsFromExternalDatasource}/>
                {errors.subject && <ErrorMessage>Vak is verplicht</ErrorMessage>}
            </Field>
            {!examIsFromExternalDatasource &&
                <>
                    <Field>
                        <FieldTitle>Titel</FieldTitle>
                        <FieldInput type="text" {...register("title", {required: true})} disabled={isInReadOnlyMode()}/>
                        {errors.title && <ErrorMessage>Titel is verplicht</ErrorMessage>}
                    </Field>
                    <Field>
                        <FieldTitle>Hoofdstukken</FieldTitle>
                        <FieldInput type="text" {...register("chapters")} disabled={isInReadOnlyMode()}/>
                    </Field>
                    <Field>
                        <FieldTitle>Paragrafen</FieldTitle>
                        <FieldInput type="text" {...register("paragraphs")} disabled={isInReadOnlyMode()}/>
                    </Field>
                </>
            }
            <Field>
                <FieldTitle>Datum van de toets</FieldTitle>
                <Controller
                    control={control}
                    name="date"
                    rules={{required: true}}
                    render={({field}) => {
                        const dateValue = dateValueFromFieldValue(field.value)
                        return <DatePickerWrapper>
                            <DatePicker
                                className={'datepicker'}
                                dateFormat={"d MMMM yyyy"}
                                placeholderText='Select date'
                                defaultValue={field.value}
                                disabled={isInReadOnlyMode() || examIsFromExternalDatasource}
                                onChange={(date) => field.onChange(date)}
                                locale="nl-NL"
                                selected={dateValue}
                                calendarStartDay={6}
                                filterDate={isWeekday}/>
                            <DatePickerLogoWrapper>
                                <DatePickerLogo src={calenderLogo}/>
                            </DatePickerLogoWrapper>
                        </DatePickerWrapper>
                    }}
                />
            </Field>
            {examIsFromExternalDatasource &&
                <Field>
                    <FieldTitle>Notities docent</FieldTitle>
                    <FieldTextArea
                        {...register("teacherNotes")}
                        disabled={true}
                    />
                </Field>
            }
            <Field>
                <FieldTitle>Notities</FieldTitle>
                <FieldTextArea
                    {...register("pupilNotes")}
                    disabled={isInReadOnlyMode()}
                />
            </Field>
            {!examIsFromExternalDatasource &&
                <>
                    <Field>
                        <FieldTitle>Aantal tijdsblokken</FieldTitle>
                        <FieldInput type="text" {...register("timeSlots")} disabled={isInReadOnlyMode()}/>
                    </Field>
                    <Field>
                        <FieldTitle>Aantal dagen</FieldTitle>
                        <FieldInput type="text" {...register("amountOfDays")} disabled={isInReadOnlyMode()}/>
                    </Field>
                </>
            }
            <Field>
                <FieldTitle>Kies een kleur</FieldTitle>
                <Controller
                    control={control}
                    name={"color"}
                    render={({field}) =>
                        <Dropdown placeholder={"Kleur"}
                                  selectedColor={field.value}
                                  options={colorOptions}
                                  defaultValue={field.value}
                                  onChange={field.onChange}
                                  disabled={isInReadOnlyMode()}
                        />
                    }
                />
            </Field>
        </FieldList>
        {!isInReadOnlyMode() && <ActionBar>
            <ActionButton text={"Opslaan"} src={checkLogo} onClick={handleSubmit(onSubmit)} isLoading={isLoading} disabled={isLoading}/>
        </ActionBar>}
    </Card>


    function setValuesBasedOnExam() {
        if (date) {
            setValue("date", date);
        } else if (exam && !!exam.date) {
            setValue("date", new Date(exam.date));
        } else {
            setValue("date", new Date());
        }

        if (!exam) {
            return;
        }
        setValue("subject", exam.subject);
        setValue("title", exam.title);
        setValue("chapters", exam.chapters);
        setValue("paragraphs", exam.paragraphs);
        setValue("timeSlots", exam.timeSlots);
        setValue("amountOfDays", exam.amountOfDays);
        setValue("teacherNotes", exam.description || exam.teacherNotes);
        setValue("pupilNotes", exam.pupilNotes);
        setValue("color", exam.color);
    }

    function onSubmit(data) {
        setIsLoading(true);
        data.date = data.date.getTime()
        if (!isMakingNewExam) {
            data.id = exam.id;
            data.externalId = exam.externalId;
        }
        Api.put('pupils/' + AppStorage.get(StorageKey.USER_ID) + '/exams/' + (isMakingNewExam ? '' : match?.params?.id), data, (response) => {
            if (isMakingNewExam) {
                history.replace('/toets/' + response.data.id, {exam: response.data})
            } else {
                onExamChange(response.data)
            }
            showBasicPopup(
                "Gegevens opgeslagen!",
                "De gegevens zijn succesvol opgeslagen.",
                "OK"
            )
        }, null, null, null, () => setIsLoading(false))
    }
}

export default ExamCard;

export function dateValueFromFieldValue(value) {
    if (!!value) {
        if (Number.isInteger(value)) {
            return new Date(value);
        } else {
            return value
        }
    } else {
        return new Date();
    }
}

const ActionBar = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    margin-top: 40px;
`;

const DatePickerWrapper = styled.div`
    position: relative;
    
    .react-datepicker-wrapper { width: 100%; }
`;

export const DatePickerLogoWrapper = styled.div`
    display: flex;
    position: absolute;
    right: 6px;
    top: 0;
    height: 100%;
    pointer-events: none;
    align-items: center;
`;

export const DatePickerLogo = styled.img`
    display: block;
    height: 24px;
    width: 24px;
`;

const FieldList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

const Field = styled.div`
    display: flex;
    flex-direction: column;
`;

const FieldTitle = styled.div`
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: ${black};
    opacity: 0.6;
`;

export const FieldInput = styled.input`
    font-family: ${sourceSansPro};
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    height: 35px;
    border: 1px solid #E2E2EB;
    border-radius: 2px;
    margin-top: 2px;
    padding: 0 9px;
`;

const FieldTextArea = styled.textarea`
    font-family: ${sourceSansPro};
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    height: 75px;
    border: 1px solid #E2E2EB;
    border-radius: 2px;
    margin-top: 2px;
    padding: 9px;
    resize: none;
`;

const ErrorMessage = styled.div`
    font-family: ${nunito};
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: ${red};
    align-self: flex-start;
    margin-top: 5px;
`;