import React, {useEffect, useState} from 'react';
import Api from '../util/Api';
import {useMenuDataContext} from '../contexts/MenuDataContext';
import {FeatureFlags} from '../constants/featureFlags';
import {Loader} from '../components/Loader';
import AppStorage, {StorageKey} from "../util/AppStorage";
import {UserType} from "../constants/enums";
import showBasicPopup from "../popups/showBasicPopup";
import {StyledGradeIcon, StyledPupilIcon} from "../menu/SideMenu";

export function AccessGate({children, userIsAuthenticated}) {
    if (userIsAuthenticated) {
        return (
            <Authenticated>
                {children}
            </Authenticated>
        );
    }

    return (
        <>
            {children}
        </>
    );
}

const Authenticated = ({children}) => {
    const [loading, setLoading] = useState(true);
    const {updateFeatureFlags, setGradeOptions, setPupilOptions} = useMenuDataContext();

    // When user has featureSchedule, update context
    useEffect(() => {
        Api.get("/access", (response) => {
            if (response?.data?.featureSchedule) {
                updateFeatureFlags([FeatureFlags.FEATURE_SCHEDULE]);
            }
            if (AppStorage.get(StorageKey.USER_TYPE) === UserType.MENTOR) {
                getDataFromApi("users/me/grades")
            } else if (AppStorage.get(StorageKey.USER_TYPE) === UserType.PUPIL) {
                getDataFromApi("users/me")
            }
        }, null, null, null)
    }, []);

    function getDataFromApi(url) {
        Api.get(url, (response) => {
            const data = response.data
            if (AppStorage.get(StorageKey.USER_TYPE) === UserType.PUPIL) {
                AppStorage.set(StorageKey.GRADE_ID, data.id)
                setPupilOptions([{value: data.id, label: data.name, icon: <StyledPupilIcon/>}])
                setGradeOptions([{value: data.id, label: data.grade, icon: <StyledGradeIcon/>}])
            } else {
                if (!AppStorage.get(StorageKey.GRADE_ID)) {
                    AppStorage.set(StorageKey.GRADE_ID, data[0].id)
                }
                const options = data.map((grade) => ({
                    value: grade.id,
                    label: grade.title,
                    icon: <StyledGradeIcon/>
                }))
                setGradeOptions(options)
            }
            setLoading(false)
        }, null, null, () => {
            showBasicPopup("Ophalen van data opties mislukt!", "Er is iets mis gegaan met het ophalen van de data opties. Probeer het zometeen nog een keer.", "OK")
        }, {})
    }

    if (loading) return <Loader/>;

    return (
        <>
            {children}
        </>
    );
}