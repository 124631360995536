import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import ScheduleMonthGridHeaderView from "./ScheduleMonthGridHeaderView";
import {ScheduleMonthConstants} from "./ScheduleMonthConstants";
import ScheduleMonthGridItemView from "./ScheduleMonthGridItemView";
import moment from "moment";
import {Loader} from "../../../components/Loader";
import Api from "../../../util/Api";
import AppStorage, {StorageKey} from "../../../util/AppStorage";
import {greyLight} from "../../../Theme";

function ScheduleMonthView(props) {
    const {
        momentObject, // Moment instance to manipulate
    } = props

    const currentDay = useRef(null);
    const [monthMoment, setMonthMoment] = useState(momentObject);
    const firstGridDayMoment = useRef(null);
    const lastGridDayMoment = useRef(null);
    const [isLoading, setLoading] = useState(false);
    const currentExams = useRef([]);
    const currentHomeworkItems = useRef([]);
    const currentTasks = useRef([]);
    const [allItems, setAllItems] = useState([]);

    useEffect(() => {
        setMonthMoment(moment(momentObject).set({
            date: 1,
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        }))
    }, [momentObject])

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        // Make sure only called once
        if(monthMoment !== momentObject) {
            currentDay.current = moment();
            const daysInPrevMonth = monthMoment.isoWeekday() - 1;
            firstGridDayMoment.current = moment(monthMoment).subtract(daysInPrevMonth, 'days').set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0
            });
            const daysUntilLastDayOfMonth = daysInPrevMonth + monthMoment.daysInMonth();
            const numberOfDaysInGrid = maxColumns * maxRows;
            const daysInNextMonth = numberOfDaysInGrid - daysUntilLastDayOfMonth;
            lastGridDayMoment.current = moment(monthMoment).endOf('month').add(daysInNextMonth, 'days').set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 999
            });

            fetchData(signal);
        }

        return () => controller.abort();
        // eslint-disable-next-line
    }, [monthMoment])

    useEffect(() => {
        if(!isLoading) {
            const newTasks = [];
            currentExams.current.forEach((exam) => {
                exam["isImportant"] = true
                exam["isFinished"] = false
                exam["isExam"] = true
                exam["isHomework"] = false
                exam["isSpareTime"] = false
                exam["sortOrder"] = -2
                exam["dateMoment"] = moment(exam.date)
                newTasks.push(exam);
            });

            currentHomeworkItems.current.forEach((homeworkItem) => {
                homeworkItem["isImportant"] = true
                homeworkItem["isFinished"] = false
                homeworkItem["isExam"] = false
                homeworkItem["isHomework"] = true
                homeworkItem["isSpareTime"] = false
                homeworkItem["sortOrder"] = -1
                homeworkItem["dateMoment"] = moment(homeworkItem.date)
                newTasks.push(homeworkItem);
            });

            currentTasks.current.forEach((task) => {
                task["dateMoment"] = moment(task.date)
                task["isImportant"] = false
                task["isExam"] = task.examId != null
                task["isHomework"] = task.isHomework != null
                task["isSpareTime"] = task.examId == null && task.homeworkItemId == null
                newTasks.push(task);
            })
            setAllItems(newTasks);
        }
        // eslint-disable-next-line
    }, [isLoading])

    const gridItems = [];

    if(firstGridDayMoment.current != null && lastGridDayMoment.current != null) {
        let loopMoment = moment(firstGridDayMoment.current);
        for (let row = 0; row < maxRows; row++) {
            for (let column = 0; column < maxColumns; column++) {
                const isOtherMonth = loopMoment.month() !== monthMoment.month()
                const dayString = (isOtherMonth) ? loopMoment.format("D MMM") : loopMoment.date()
                gridItems.push(<GridItem key={`${row} ${column}`} gridColumn={column + " " + (column + 1)}
                                         gridRow={row + " " + (row + 1)}>
                    <ScheduleMonthGridItemView
                        dayNumber={dayString}
                        isCurrentDay={loopMoment.isSame(currentDay.current, 'day')}
                        isOtherMonth={isOtherMonth}
                        onTitleClick={(item) => {
                            props.onTitleClick(item, fetchData);
                        }}
                        items={allItems
                            // eslint-disable-next-line
                            .filter((item) => item.dateMoment.isSame(loopMoment, 'day'))
                            .sort((item1, item2) => item1.sortOrder - item2.sortOrder)}
                    />
                </GridItem>)
                loopMoment = loopMoment.add(1, 'days');
            }
        }
    }

    return <Container>
        <ScheduleMonthGridHeaderView/>
        <Grid>
            {gridItems}
        </Grid>
        {isLoading && <Loader/>}
    </Container>

    function fetchData(signal) {
        setLoading(true);
        Promise.all([
            getAllExamsForCurrentMonth(signal),
            getAllHomeworkItemsForCurrentMonth(signal),
            getAllTasksForCurrentMonth(signal),
        ]).then(() => {
            setLoading(false);
        })
    }

    function getAllExamsForCurrentMonth(signal) {
        const config = {
            params: {
                'filter[date][GT]': firstGridDayMoment.current.valueOf(),
                'filter[date][LE]': lastGridDayMoment.current.valueOf(),
            }, signal
        }

        return Api.get("/pupils/" + AppStorage.get(StorageKey.PUPIL_ID) + "/exams", (response) => {
            currentExams.current = response.data;
        }, null, null, null, config)
    }

    function getAllHomeworkItemsForCurrentMonth(signal) {
        const config = {
            params: {
                'filter[date][GT]': firstGridDayMoment.current.valueOf(),
                'filter[date][LE]': lastGridDayMoment.current.valueOf(),
            }, signal
        }

        return Api.get("/pupils/" + AppStorage.get(StorageKey.PUPIL_ID) + "/homeworkItems", (response) => {
            currentHomeworkItems.current = response.data;
        }, null, null, null, config)
    }

    function getAllTasksForCurrentMonth(signal) {
        const config = {
            params: {
                'filter[date][GT]': firstGridDayMoment.current.valueOf(),
                'filter[date][LE]': lastGridDayMoment.current.valueOf(),
            }, signal
        }

        return Api.get("pupils/" + AppStorage.get(StorageKey.PUPIL_ID) + "/tasks", (response) => {
            currentTasks.current = response.data;
        }, null, null, null, config)
    }
}

const cardWidth = ScheduleMonthConstants.cardWidth;
const cardHeight = ScheduleMonthConstants.cardHeight;
const maxColumns = ScheduleMonthConstants.maxColumns;
const maxRows = ScheduleMonthConstants.maxRows;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  padding: 10px 40px 40px 40px;
`;

const Grid = styled.div`
  //background-color: white;
  background-color: #5659920F;
  border-radius: 3px;
  border: 1px solid #5659920F;
  box-shadow: 0px 2px 0px 0px ${greyLight};
  display: grid;
  width: ${maxColumns * cardWidth}px;
  height: ${maxRows * cardHeight}px;
  grid-template-columns: repeat(${maxColumns}, ${cardWidth}px);
  grid-template-rows: repeat(${maxRows}, ${cardHeight}px);
  grid-gap: 1px;
  overflow: hidden;
  flex: 1 0 auto;
`;

const GridItem = styled.div`
  background-color: white;
  grid-column: ${props => props.gridColumn};
  grid-row: ${props => props.gridRow};
`;

export default ScheduleMonthView;