import Swal from "sweetalert2";
import React, {useEffect, useRef, useState} from "react";
import withReactContent from "sweetalert2-react-content";
import closeIcon from "../resources/images/close.svg";
import verticalMenuIcon from "../resources/images/ic-menu-vertical.svg";
import DatePicker, {registerLocale} from "react-datepicker";
import {black, nunito, purple, purpleDark, purpleLighter, purpleLightest, red, sourceSansPro, white, greyLight} from "../Theme";
import calenderLogo from "../resources/images/calendarBlack.svg";
import tasktypeLogo from "../resources/images/tasktype.svg";
import submitLogo from "../resources/images/submit.svg";
import submitGreyLogo from "../resources/images/submitGrey.svg";
import labelLogo from "../resources/images/label.svg";
import finishedLogo from "../resources/images/uncheckedWhiteFill.svg";
import clockIcon from "../resources/images/ic-clock.svg";
import trashIconBlack from "../resources/images/ic-trashBlack.svg";
import Dropdown from "./Dropdown";
import ActionButton from "../components/ActionButton";
import checkLogo from "../resources/images/checkGreen.svg";
import {Controller, useForm} from "react-hook-form";
import styled from "styled-components";
import Api from "../util/Api";
import {dateValueFromFieldValue, FieldInput} from "../screens/exam/ExamCard";
import nl from "date-fns/locale/nl";
import {isInReadOnlyMode} from "../App";
import AppStorage, {StorageKey} from "../util/AppStorage";
import {TaskMenu} from "../screens/TaskCard";
import TextareaAutosize from 'react-textarea-autosize';
import getReadableTimeDifferenceSince from "../util/Methods";
import {device} from "../constants/breakpoints";
import {hideNativeNavigation, showNativeNavigation} from "../AppInterface";
import {UserType} from "../constants/enums";
import moment from "moment/moment";

export const ShowTaskPopup = withReactContent(Swal)

function showTaskPopup(task, itemLabel, onSave, onDelete, onCopy, defaultDate, reloadTasks, homeworkItemOrExam = null) {
    hideNativeNavigation();
    ShowTaskPopup.fire({
        html: (
            <PopupContent
                homeworkItemOrExam={homeworkItemOrExam}
                task={task}
                defaultDate={defaultDate}
                itemLabel={itemLabel}
                onSave={(task) => {
                    ShowTaskPopup.close()
                    onSave(task)
                }}
                onDelete={(task) => {
                    ShowTaskPopup.close()
                    onDelete(task)
                }}
                onCopy={(task) => {
                    ShowTaskPopup.close()
                    onCopy(task)
                }}
            />
        ),
        customClass: {
            container: "swal2-container-task",
            htmlContainer: "swal2-html-container-task",
            popup: "swal2-popup-task"
        },
        heightAuto: false,
        showCancelButton: false,
        showConfirmButton: false
    }).then(showNativeNavigation).then(reloadTasks);
}

function PopupContent(props) {
    registerLocale("nl-NL", nl);
    const isMakingNewTask = !(props.task && props.task.id)
    const isMakingNewExamTask = !!(isMakingNewTask && props.task && props.task.examId);
    const isMakingNewHomeworkTask = !!(isMakingNewTask && props.task && props.task.homeworkItemId);
    const isFinishedDefault = (!isMakingNewTask && props.task.isFinished) ? 1 : 0;
    const titleDefault = (!isMakingNewTask && props.task.title) ? props.task.title : null;
    const taskDateDefault = (!isMakingNewTask && props.task.date) ? new Date(props.task.date) : new Date();
    const defaultDate = (isMakingNewTask && props.defaultDate) && new Date(props.defaultDate);
    const noteDefault = (!isMakingNewTask && props.task.note) ? props.task.note : undefined;
    const timeEstimateDefault = (!isMakingNewTask && props.task.timeEstimate) ? props.task.timeEstimate : undefined;
    const startTimeDefault = (!isMakingNewTask && props.task.startTime) ? new Date(props.task.startTime) : undefined;
    const endTimeDefault = (!isMakingNewTask && props.task.endTime) ? new Date(props.task.endTime) : undefined;
    const userType = AppStorage.get(StorageKey.USER_TYPE)
    const userMayComment = userType === UserType.PUPIL || userType === UserType.MENTOR

    let typeDefault = null;
    let subjectDefault = null;
    let itemDefault = null;

    if (!isMakingNewTask || isMakingNewExamTask || isMakingNewHomeworkTask) {
        if (props.task.homeworkItemId) {
            typeDefault = "Huiswerk";
            subjectDefault = props.homeworkItemOrExam ? props.homeworkItemOrExam.subject : props.task.subject;
            itemDefault = props.task.homeworkItemId;
        } else if (props.task.examId) {
            typeDefault = "Toets";
            subjectDefault = props.homeworkItemOrExam ? props.homeworkItemOrExam.subject : props.task.subject;
            itemDefault = props.task.examId;
        } else {
            itemDefault = null;
            subjectDefault = null;
            typeDefault = "Vrije tijd"
        }
    }

    const {watch, register, getValues, control, handleSubmit, setValue, formState: {errors}} = useForm();
    const [typeState, setTypeState] = useState(typeDefault)
    const [subject, setSubject] = useState(subjectDefault)
    const [items, setItems] = useState([])
    const [comments, setComments] = useState([]);
    const [taskMenuOpen, setTaskMenuOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!isMakingNewTask) {
            getCommentsFromApi();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let type = null;
        if (isMakingNewHomeworkTask) {
            type = "Huiswerk"
        } else if (isMakingNewExamTask) {
            type = "Toets"
        }
        setValue('type', type ? type : typeDefault)
        setValue('isFinished', isFinishedDefault)
        setValue('date', defaultDate ? defaultDate : taskDateDefault)
        setValue('timeEstimate', timeEstimateDefault)
        setValue('subject', subjectDefault)
        setValue('item', itemDefault)
        setValue('note', noteDefault)
        setValue('set', noteDefault)
        setValue('startTime', startTimeDefault)
        setValue('endTime', endTimeDefault)
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (typeState === typeDefault) {
            setValue("subject", subjectDefault)
            setValue("item", itemDefault)
        } else {
            setValue("subject", null)
            setValue("item", null)
        }
        // eslint-disable-next-line
    }, [items]);

    useEffect(() => {
        setTypeState(getValues()['type'])
        setValue("subject", null)
        setValue("item", null)
        // eslint-disable-next-line
    }, [watch()['type']]);

    useEffect(() => {
        setSubject(getValues()['subject'])
        if (getValues()['subject'] !== subjectDefault) {
            setValue("item", null)
        }
        // eslint-disable-next-line
    }, [watch()['subject']]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        getOptionsFromApi(typeState === "Toets" ? 'exams' : 'homeworkItems', signal)

        return () => controller.abort();
        // eslint-disable-next-line
    }, [typeState]);

    let subjectOptions = items.filter((value, index, self) => {
        return self.findIndex(item => item.subject === value.subject) === index
    }).map((item) => {
        return {
            value: item.subject,
            label: item.subject
        }
    })

    let itemOptions = items.filter(item => { return item.subject === subject }).map((item) => {
        return {
            value: item.id,
            label: getItemLabel(item)
        }
    })

    //preselect the already chosen exam without having to do the get exams/homeworkItems api call first
    if (itemOptions.length === 0 && itemDefault) {
        subjectOptions.push({
            value: itemDefault,
            label: props.itemLabel
        })

        itemOptions.push({
            value: itemDefault,
            label: props.itemLabel
        })
    }

    const typeOptions = [
        {
            value: "Toets",
            label: "Toets"
        },
        {
            value: "Huiswerk",
            label: "Huiswerk"
        },
        {
            value: "Vrije tijd",
            label: "Vrije tijd"
        }
    ];

    const timeEstimateOptions = [
        {
            value: "15 min",
            label: "15 min"
        },
        {
            value: "30 min",
            label: "30 min"
        },
        {
            value: "45 min",
            label: "45 min"
        },
        {
            value: "60 min",
            label: "60 min"
        },
        {
            value: "75 min",
            label: "75 min"
        },
        {
            value: "90 min",
            label: "90 min"
        },
        {
            value: "120 min",
            label: "120 min"
        },
        {
            value: "180 min",
            label: "180 min"
        }
    ];

    const finishedOptions = [
        {
            value: 1,
            label: "Klaar"
        },
        {
            value: 0,
            label: "Nog niet klaar"
        }
    ];

    return <ContentWrapper>
        <TopContent roundedCornersOnMobile={!isMakingNewTask} fillOnMobile={isMakingNewTask}
                    bottomPadding={isInReadOnlyMode() ? "16px" : "0px"}>
            {!isMakingNewTask &&
            <TaskMenu {...props} copyAction={copyTask} deleteAction={deleteTask} disabled={isLoading} taskMenuOpen={taskMenuOpen} location={"right"}/>}
            <TaskMenuOverlay onClick={() => setTaskMenuOpen(false)} hidden={!taskMenuOpen}/>
            <TopBarWrapper>
                <CloseButton src={closeIcon} onClick={() => {
                    ShowTaskPopup.clickConfirm()
                    setTaskMenuOpen(false)
                }}/>
                {!isMakingNewTask &&
                <MenuIcon $display={!isInReadOnlyMode()} src={verticalMenuIcon} onClick={handleOnMenuClicked}/>}
            </TopBarWrapper>
            <PaddedContent>
                <Title type="text"
                       defaultValue={titleDefault}
                       placeholder={"Titel..."}
                       maxLength={50}
                       disabled={isInReadOnlyMode()}
                       hasError={errors.title}
                       {...register("title", {required: true})}
                />
                {errors.title && <ErrorMessage noIndent="true">Je taak heeft nog geen titel</ErrorMessage>}

                <InfoItemList>

                    <InfoItem>
                        <InfoItemLogo src={tasktypeLogo}/>
                        <Controller
                            control={control}
                            name={"type"}
                            rules={{required: true}}
                            render={({field}) =>
                                <Dropdown placeholder={"Kies toets, huiswerk of vrije tijd"}
                                          options={typeOptions}
                                          defaultValue={field.value}
                                          onChange={field.onChange}
                                          disabled={isInReadOnlyMode()}
                                          hasError={errors.type}
                                />
                            }
                        />
                    </InfoItem>
                    {errors.type && <ErrorMessage>Selecteer een type taak</ErrorMessage>}

                    {typeState !== "Vrije tijd" && typeState != null &&
                        <>
                            <InfoItem>
                                <InfoItemLogo src={labelLogo}/>
                                <Controller
                                    control={control}
                                    name={"subject"}
                                    rules={{required: true}}
                                    render={({field}) => {
                                        return <Dropdown placeholder={"Vak..."}
                                                  defaultValue={field.value}
                                                  options={subjectOptions}
                                                  onChange={field.onChange}
                                                  disabled={isInReadOnlyMode()}
                                                  hasError={errors.subject}
                                        />
                                    }}
                                />
                            </InfoItem>
                            {errors.subject && <ErrorMessage>Selecteer een vak</ErrorMessage>}
                        </>
                    }

                    {typeState !== "Vrije tijd" && subject != null &&
                    <>
                        <InfoItem>
                            <InfoItemLogo src={labelLogo}/>
                            <Controller
                                control={control}
                                name={"item"}
                                rules={{required: true}}
                                render={({field}) =>
                                    <Dropdown placeholder={typeState === "Toets" ? "Toets..." : "Huiswerk..."}
                                              defaultValue={field.value}
                                              options={itemOptions}
                                              onChange={field.onChange}
                                              disabled={isInReadOnlyMode()}
                                              hasError={errors.item}
                                    />
                                }
                            />
                        </InfoItem>
                        {errors.item && <ErrorMessage>Koppel je taak aan huiswerk of aan een toets</ErrorMessage>}
                    </>
                    }

                    {typeState !== "Vrije tijd"
                    && <InfoItem>
                        <InfoItemLogo src={clockIcon}/>
                        <Controller
                            control={control}
                            name={"timeEstimate"}
                            rules={{required: false}}
                            render={({field}) =>
                                <Dropdown placeholder={"Geschatte tijd"}
                                          defaultValue={field.value}
                                          options={timeEstimateOptions}
                                          onChange={field.onChange}
                                          disabled={isInReadOnlyMode()}
                                />
                            }
                        />
                    </InfoItem>
                    }

                    <InfoItem>
                        <InfoItemLogo src={calenderLogo}/>
                        <Controller
                            control={control}
                            name={"date"}
                            rules={{required: true}}
                            render={({field}) => {
                                const dateValue = dateValueFromFieldValue(field.value)
                                return <DatePickerWrapper>
                                    <StyledDatePicker
                                        customInput={<FieldInput type="text"/>}
                                        onChangeRaw={(e) => e.preventDefault()}
                                        className={'datepicker'}
                                        dateFormat={"d MMMM yyyy"}
                                        placeholderText='Select date'
                                        onChange={(date) => field.onChange(date)}
                                        locale="nl-NL"
                                        selected={dateValue}
                                        calendarStartDay={6}
                                        disabled={isInReadOnlyMode()}
                                        hasError={errors.date}
                                    />
                                </DatePickerWrapper>
                            }}
                        />
                    </InfoItem>
                    {errors.date && <ErrorMessage>Selecteer een datum</ErrorMessage>}

                    {typeState === "Vrije tijd" &&
                    <InfoItemSmallContainer>
                        <InfoItemSmall>
                            <InfoItemLogo src={clockIcon}/>
                            <Controller
                                control={control}
                                name={"startTime"}
                                rules={{required: false}}
                                render={({field}) => {
                                    const dateValue = field.value
                                    return <DatePickerWrapper>
                                        <StyledDatePicker
                                            customInput={<FieldInput type="text"/>}
                                            onChangeRaw={(e) => e.preventDefault()}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            className={'datepicker'}
                                            dateFormat={"HH:mm"}
                                            placeholderText='Start...'
                                            timeCaption="Start"
                                            onChange={(date) => {
                                                field.onChange(date)
                                            }}
                                            locale="nl-NL"
                                            selected={dateValue}
                                            disabled={isInReadOnlyMode()}/>
                                    </DatePickerWrapper>
                                }}
                            />
                        </InfoItemSmall>

                        <UntilText>tot</UntilText>

                        <InfoItemSmall>
                            <Controller
                                control={control}
                                name={"endTime"}
                                rules={{required: false}}
                                render={({field}) => {
                                    const dateValue = field.value
                                    return <DatePickerWrapper>
                                        <StyledDatePicker
                                            customInput={<FieldInput type="text"/>}
                                            onChangeRaw={(e) => e.preventDefault()}
                                            className={'datepicker'}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            dateFormat={"HH:mm"}
                                            placeholderText='Eind...'
                                            timeCaption="Eind"
                                            onChange={(date) => field.onChange(date)}
                                            locale="nl-NL"
                                            selected={dateValue}
                                            disabled={isInReadOnlyMode()}/>
                                    </DatePickerWrapper>
                                }}
                            />
                        </InfoItemSmall>
                    </InfoItemSmallContainer>
                    }

                    {typeState !== "Vrije tijd"
                    && <InfoItem>
                        <InfoItemLogo src={finishedLogo}/>
                        <Controller
                            control={control}
                            name={"isFinished"}
                            rules={{required: true}}
                            render={({field}) =>
                                <Dropdown
                                    placeholder={"Status"}
                                    options={finishedOptions}
                                    defaultValue={field.value}
                                    onChange={field.onChange}
                                    disabled={isInReadOnlyMode()}
                                />
                            }
                        />
                    </InfoItem>
                    }

                </InfoItemList>

                <NoteWrapper>
                    <SubHeaderTitle>Aantekeningen</SubHeaderTitle>
                    <Note type="textarea"
                          {...register("note")}
                          disabled={isInReadOnlyMode()}/>
                </NoteWrapper>
                {!isInReadOnlyMode() &&
                <BottomBar>
                    <ActionButton text={"Opslaan"} src={checkLogo} onClick={handleSubmit(onSubmit)} isLoading={isLoading} disabled={isLoading} />
                </BottomBar>
                }
            </PaddedContent>
        </TopContent>
        {(!isMakingNewTask) &&
        <BottomContent bottomPadding={userMayComment ? "0px" : "16px"}>
            <PaddedContentComments>
                <CommentsContainer>
                    <SubHeaderTitle>Opmerkingen</SubHeaderTitle>
                    {comments.length === 0
                        ? <NoCommentsLabel>Er zijn nog geen opmerkingen...</NoCommentsLabel>
                        : <CommentsList>
                            {comments.map((c) => {
                                return <Comment key={c.id}>
                                    <CommentTopBar>
                                        <CommentTitle>{c.authorId === AppStorage.get(StorageKey.USER_ID) ? "Ik" : c.authorName}</CommentTitle>
                                        <CommentSubtitle>{getReadableTimeDifferenceSince(c.createdDate)}</CommentSubtitle>
                                        {c.authorId === AppStorage.get(StorageKey.USER_ID) &&
                                        <CommentDeleteIcon onClick={() => deleteComment(c)} src={trashIconBlack} $disabled={isLoading} />}
                                    </CommentTopBar>
                                    <CommentText>{c.text}</CommentText>
                                </Comment>
                            })}
                        </CommentsList>
                    }
                </CommentsContainer>
            </PaddedContentComments>
            {userMayComment && <CommentCreationBar onComment={createComment} /> }
            {userMayComment && <CommentCreationBar onComment={createComment} mobile /> }
        </BottomContent>
        }
    </ContentWrapper>

    function getItemLabel(item) {
        if (item.description) {
            return item.description.substring(0, 100) + "..."
        } else if (item.teacherNotes) {
            return item.teacherNotes.substring(0, 100) + "..."
        } else {
            const date = moment(item.date).format("dd D MMM YYYY")
            return item.title ? (`${item.title} (${date})`) : (`${item.subject} (${date})`)
        }
    }

    function deleteComment(c) {
        setIsLoading(true);
        Api.delete('tasks/' + props.task.id + '/comments/' + c.id, onSuccess, null, () => setIsLoading(false), () => setIsLoading(false));

        function onSuccess() {
            setComments(comments.filter((c2) => {
                return c2.id !== c.id
            }))
            setIsLoading(false);
        }
    }

    function handleOnMenuClicked(e) {
        e.stopPropagation()
        setTaskMenuOpen(!taskMenuOpen)
    }

    function copyTask() {
        setIsLoading(true);
        const data = getValues();
        data.examId = data.type === "Toets" ? data.item : null
        data.homeworkItemId = data.type === "Huiswerk" ? data.item : null
        data.date = !!data.date ? data.date.getTime() : defaultDate
        data.endTime = !!data.endTime ? data.endTime.getTime() : null
        data.startTime = !!data.startTime ? data.startTime.getTime() : null
        data.title = '(KOPIE) ' + data.title
        data.isFinished = 0
        Api.put('pupils/' + AppStorage.get(StorageKey.USER_ID) + '/tasks', data, (response) => {
            props.onCopy(response.data)
            setIsLoading(false)
        }, null, () => setIsLoading(false), () => setIsLoading(false))
    }

    function deleteTask() {
        setIsLoading(true);
        Api.delete('tasks/' + props.task.id, () => {
            props.onDelete(props.task)
            setIsLoading(false)
        }, null, () => setIsLoading(false), () => setIsLoading(false))
    }

    function createComment(text) {
        let commentJson = {
            taskId: props.task.id,
            text: text
        }

        Api.put('tasks/' + props.task.id + '/comments', commentJson, (response) => {
            setComments([...comments, response.data])
        })
    }

    function onSubmit(data) {
        setIsLoading(true);
        data.examId = data.type === "Toets" ? data.item : null
        data.homeworkItemId = data.type === "Huiswerk" ? data.item : null
        data.date = data.date.getTime()

        if (!isMakingNewTask) {
            data.id = props.task.id
        }

        if (typeState === "Vrije tijd") {
            data.startTime = (data.startTime ? data.startTime.getTime() : null)
            data.endTime = (data.endTime ? data.endTime.getTime() : null)
        } else {
            data.startTime = null
            data.endTime = null
        }
        Api.put('pupils/' + AppStorage.get(StorageKey.USER_ID) + '/tasks/' + (isMakingNewTask ? '' : props.task.id), data, (response) => {
            props.onSave(response.data)
            setIsLoading(false);
        }, null, () => setIsLoading(false), () => setIsLoading(false))
    }

    function getOptionsFromApi(optionType, signal) {
        Api.get(optionType, (response) => {
            setItems(response.data)
        }, null, null, null, { signal })
    }

    function getCommentsFromApi() {
        Api.get('tasks/' + props.task.id + '/comments', (response) => {
            setComments(response.data)
        })
    }
}

export function CommentCreationBar({onComment, ...props}) {
    const [submittable, setSubmittable] = useState(false)
    const inputRef = useRef()

    function createComment() {
        if (inputRef.current.value) {
            onComment(inputRef.current.value)
            inputRef.current.value = null
            setSubmittable(false)
        }
    }

    return <CommentCreationBarWrapper {...props}>
        <NewComment type="text" placeholder="Opmerking..." ref={inputRef} onChange={(e) => {
            setSubmittable(!!inputRef.current.value)
        }}/>
        <SubmitIcon src={submittable ? submitLogo : submitGreyLogo} onClick={createComment} disabled={!submittable}/>
    </CommentCreationBarWrapper>
}

const StyledDatePicker = styled(DatePicker)`
    font-family: ${sourceSansPro};
    font-size: 16px; 
    margin: 0;
    min-height: 38px;
    border-radius: 4px;
    border-color: ${props => props.hasError ? red : 'hsl(0, 0%, 80%)'};
`
const DatePickerWrapper = styled.div`
    width: 100%;
`

const BottomBar = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 16px;
    div:last-of-type {
       margin-left: auto;
    }
`;

const NewComment = styled(TextareaAutosize)`
    border: 1px solid;
    border-radius: 3px;
    padding-left: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 12px;
    flex-grow: 1;
    outline: none;
    border-color: hsl(0, 0%, 80%);
    font-family: ${sourceSansPro};
    font-style: normal;
    font-size: 16px;
    resize: none;
    min-height: 38px;
    overflow: hidden;
    ::placeholder,
    ::-webkit-input-placeholder {
        font-style: italic;
        opacity: 0.5;
    }
    :-ms-input-placeholder {
        font-style: italic;
        opacity: 0.5;
    }
`;

const Note = styled.textarea`
    margin-top: 5px;
    padding: 10px;
    color: ${black}
    resize: none;
    border-radius: 3px;
    font-family: ${sourceSansPro};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    min-height: 100px;
    border-color: hsl(0, 0%, 80%);
    resize: none;
`

const CommentCreationBarWrapper = styled.div`
    margin-top: 25px;
    justify-content: end;
    align-items: center;
    flex-direction: row;
    width: 100%;
    background-color: ${purpleLighter};
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 30px;
    padding-right: 30px;
    gap: 12px;

    ${(props) => props.mobile ? "position: fixed;" : ""}
    ${(props) => props.mobile ? "bottom: 0;" : ""}
    ${(props) => props.mobile ? "margin-top: 40px;" : ""}
    ${(props) => props.mobile ? `
    display: none;
    @media screen and ${device.mobileL} {
        display: flex;
    }` : "display: flex;"}
    
`;

const NoCommentsLabel = styled.div`
    font-family: ${sourceSansPro};
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    opacity: 0.5;
    text-align: left;
    margin-top: 15px;
`;

const SubHeaderTitle = styled.div`
    color: ${black};
    font-family: ${nunito};
    font-style: normal;
    opacity: 0.6;
    font-weight: normal;
    text-align: left;
    font-size: 14px;
`

const Comment = styled.div`
    border: 1px solid #5659920F;
    box-shadow: 0px 2px 0px 0px ${greyLight};
    border-radius: 3px;
    background-color: ${purpleLightest};
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 9px;
`;

const CommentTitle = styled.div`
    font-family: ${nunito};
    font-style: bold;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    flex-grow: 1;
    text-align: left;
    color: ${purpleDark};
`;

const CommentSubtitle = styled.div`
    font-family: ${sourceSansPro};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    opacity: 0.5;
`;

const CommentDeleteIcon = styled.img`
    src: ${props => props.src};
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-left: 12px;
    opacity: 0.5;
    ${({$disabled}) => $disabled && 'pointer-events: none;'};
`

const CommentText = styled.div`
    font-family: ${sourceSansPro};
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    white-space: pre-wrap
`

const CommentTopBar = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
`;

const CommentsList = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    gap: 8px;
`;

const CommentsContainer = styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-content: left;
    width: 100%;
`;

const NoteWrapper = styled.div`
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-content: left;
    width: 100%;
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media screen and ${device.mobileL} {
        min-height: 100vh;
    }
`;

const TopContent = styled.div`
    background-color: ${white};
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding-bottom: ${props => props.bottomPadding};
    @media screen and ${device.mobileL} {
        ${(props) => props.roundedCornersOnMobile ? "border-radius: 0 0 5px 5px;" : "border-radius: 0;"}
        ${(props) => props.fillOnMobile ? "flex-grow: 1;" : ""}
    }
`

const BottomContent = styled.div`
    background-color: ${white};
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    overflow: hidden;
    border-radius: 5px;
    padding-bottom: ${props => props.bottomPadding};
    @media screen and ${device.mobileL} {
        border-radius: 5px 5px 0 0;
        flex-grow: 1;
    }
`

const PaddedContentComments = styled.div`
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    @media screen and ${device.mobileL} {
        flex-grow: 1;
    }
`;

const PaddedContent = styled.div`
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
`;

const InfoItemList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 28px;
`;

const InfoItemLogo = styled.img`
    
`;

const Title = styled.input`
    border: 1px solid;
    border-color: ${props => props.hasError ? red : 'hsl(0, 0%, 80%)'};
    border-radius: 3px;
    outline: none;
    font-family: ${nunito};
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    color: ${purple};
    padding-left: 11px;
    width: 100%;
    height: 38px;
    margin-right: auto;
`;

const InfoItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const InfoItemSmall = styled.div`
    display: inline-flex;
    flex-grow: 1;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const InfoItemSmallContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const UntilText = styled.div`
    min-width: 25px;
`;

const TopBarWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px; 
`

const ErrorMessage = styled.div`
    font-family: ${nunito};
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color ${red};
    align-self: flex-start;
    margin-left: ${props => props.noIndent ? "0px" : "34px"};
    margin-top: ${props => props.noIndent ? "5px" : "-10px"};
`;

export const SubmitIcon = styled.img`
    src: ${props => props.src};
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    width: 20px;
    height: 20px;
`;

export const TaskMenuOverlay = styled.div`
    background: ${black};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.6;
    z-index: 100;
`;
export const CloseButton = styled.img`
    src: ${props => props.src};
    cursor: pointer;
    width: 14px;
    height: 14px;
`

export const MenuIcon = styled.img`
`;

export default showTaskPopup;