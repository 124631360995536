import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {purpleDark, red} from "../Theme";
import {useHistory, useLocation} from "react-router-dom";
import {RoutesWithoutNav} from "../App";
import {logoutAndGoToLogin, PupilInfoBar, StyledGradeIcon, StyledPupilIcon,} from "./SideMenu";
import logoutLogo from "../resources/images/logout.svg";
import {useMenuDataContext} from "../contexts/MenuDataContext";

function TopMenu() {
    const {pupilOptions, gradeOptions} = useMenuDataContext();
    const history = useHistory();
    const location = useLocation();
    const [isVisible, setVisible] = useState(true);

    useEffect(() => {
        if (RoutesWithoutNav.includes(location.pathname)) {
            setVisible(false);
        } else {
            setVisible(true);
        }
    }, [location.pathname]);

    return (
        <TopMenuWrapper $visible={isVisible}>
            <PupilInfoWrapper>
                <PupilInfoBar type={"grade"} options={gradeOptions} icon={<StyledGradeIcon/>}/>
                <PupilInfoBar type={"pupil"} options={pupilOptions} icon={<StyledPupilIcon/>}/>
            </PupilInfoWrapper>
            <LogOutButton src={logoutLogo} to={"/login"} onClick={() => {
                logoutAndGoToLogin(history)
            }}/>
        </TopMenuWrapper>
    )
}

function LogOutButton(props) {
    return (
        <LogOutButtonWrapper onClick={props.onClick}>
            <MenuItemLogo src={props.src}/>
        </LogOutButtonWrapper>
    );
}

const TopMenuWrapper = styled.div`
    background: ${purpleDark};
    width: 100%;
    height: 43px;
    display: ${({$visible}) => $visible ? "flex" : "none"};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    gap: 10px;
    padding: 5px 17px;
`;

const PupilInfoWrapper = styled.div`
    width: calc(100% - 24px);
    height: 100%;
    display: grid;
    grid-template-columns: minmax(0, 33%) 1fr;
    align-items: center;
    gap: 10px;
`;

const LogOutButtonWrapper = styled.div`
    text-decoration: none;
    background: ${props => props.$isActive ? red : undefined};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    height: 42px;
    cursor: pointer;
    width: 24px;
`;

const MenuItemLogo = styled.img`
    color: white;
    display: block;
    height: ${props => props.$size ? props.$size : "24px"};
    width: ${props => props.$size ? props.$size : "24px"};
`;

export default TopMenu;