import React, {useState} from "react";
import {Answer} from "../../constants/enums";
import styled from "styled-components";
import {black, purple, sourceSansPro, white} from "../../Theme";
import {isInReadOnlyMode} from "../../App";

function QuestionAnswerRow({step, ...props}) {
    const [selectedAnswer, setSelectedAnswer] = useState(props.answer ? parseInt(props.answer.value) : undefined);

    return (
        <QuestionAnswerRowRoot>
            <AnswerButton
                isActive={!isInReadOnlyMode()}
                answerValue={Answer.NEVER}
                isSelected={selectedAnswer === Answer.NEVER}
                step={step}
                onClick={() => handleAnswerClick(props.question.id, Answer.NEVER)}
            />

            <AnswerButton
                isActive={!isInReadOnlyMode()}
                answerValue={Answer.SOMETIMES}
                isSelected={selectedAnswer === Answer.SOMETIMES}
                step={step}
                onClick={() => handleAnswerClick(props.question.id, Answer.SOMETIMES)}
            />

            <AnswerButton
                isActive={!isInReadOnlyMode()}
                answerValue={Answer.OFTEN}
                isSelected={selectedAnswer === Answer.OFTEN}
                step={step}
                onClick={() => handleAnswerClick(props.question.id, Answer.OFTEN)}
            />

            <AnswerButton
                isActive={!isInReadOnlyMode()}
                answerValue={Answer.ALWAYS}
                isSelected={selectedAnswer === Answer.ALWAYS}
                step={step}
                onClick={() => handleAnswerClick(props.question.id, Answer.ALWAYS)}
            />
        </QuestionAnswerRowRoot>
    );

    function AnswerButton({step, ...props}) {
        return (
            <AnswerButtonRoot isActive={props.isActive} $selected={props.isSelected} $buttonColor={step?.efColor ?? purple} onClick={props.onClick}>
                <AnswerButtonText $selected={props.isSelected} $hasDarkText={!!step?.hasDarkText}>{mapAnswerValueToString(props.answerValue)}</AnswerButtonText>
            </AnswerButtonRoot>
        );
    }

    function handleAnswerClick(questionId, answerValue) {
        const answer = props.answer;
        const answerId = props.answer ? props.answer.id : ""
        const answerData = {
            id: answer ? answer.id : null,
            questionId: questionId,
            periodId: props.periodId,
            value: answerValue
        }
        putAnswer(answerData, answerValue, answerId);
    }

    function mapAnswerValueToString(answerValue) {
        switch (answerValue) {
            case Answer.NEVER:
                return "Nooit";
            case Answer.SOMETIMES:
                return "Soms";
            case Answer.OFTEN:
                return "Vaak";
            case Answer.ALWAYS:
                return "Altijd";
            default:
                return "";
        }
    }

    function putAnswer(answerData, answerValue, answerId) {
        props.onPut(props.answer, answerData)
        setSelectedAnswer(answerValue);
    }
}

export const AnswerButtonRoot = styled.div`
    width: 68px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({$buttonColor, $selected}) => $selected ? $buttonColor : "#808080"};
    border-radius: 3px;
    background: ${({$buttonColor, $selected}) => $selected ? $buttonColor : undefined};
    pointer-events: ${props => props.isActive ? 'default' : 'none'};
    cursor: pointer;
    &:hover {
        background-color: ${({$selected}) => $selected ? undefined : "#E5E5E5"};
    }
`;

export const AnswerButtonText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
    color: ${({$selected, $hasDarkText}) => ($selected && !$hasDarkText) ? white : black};
`;

export const QuestionAnswerRowRoot = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
`;

export default QuestionAnswerRow;