import {createContext, useContext, useState} from 'react';


const MenuDataContext = createContext(null);

export const MenuDataProvider = ({children}) => {
    const [featureFlags, setFeatureFlags] = useState([]);
    const [gradeOptions, setGradeOptions] = useState([]);
    const [pupilOptions, setPupilOptions] = useState([]);


    const updateFeatureFlags = (data) => {
        setFeatureFlags((prevValues) => ([
            ...prevValues, ...data
        ]))
    }

    return (
        <MenuDataContext.Provider
            value={{
                featureFlags,
                setFeatureFlags,
                updateFeatureFlags,
                gradeOptions,
                setGradeOptions,
                pupilOptions,
                setPupilOptions
            }}
        >
            {children}
        </MenuDataContext.Provider>
    );
}

export const useMenuDataContext = () => useContext(MenuDataContext);