import React, {useState} from 'react';
import {DateNavigatorDirection, DateNavigatorMode, SwitchOption} from "../../constants/enums";
import arrowLeft from "../../resources/images/ic-arrowLeft.svg";
import arrowRight from "../../resources/images/ic-arrowRight.svg";
import notificationIconFilled from "../../resources/images/ic-notification-filled.svg";
import notificationIconOutline from "../../resources/images/ic-notification-outline.svg";
import styled from "styled-components";
import {nunito, purple, purpleLighter} from "../../Theme";
import {device} from "../../constants/breakpoints";
import moment from "moment";
import Switch from "../Switch";
import NotificationMenu from "../../screens/schedule/notificationmenu/NotificationMenu";

function DateNavigator(props) {

    const {
        momentObject, // Moment instance to manipulate
        defaultNavigatorMode,
        isOnMentorSchedule,
        onAfterNavigation,
        onModeSwitch,
        onBeforeNavigation,
        disableNotificationMenu,
        notifications, // NotificationMenu only, only provide when this menu is not disabled
        whatsNewObjects, // NotificationMenu only, only provide when this menu is not disabled
        navigateToComment // NotificationMenu only, only provide when this menu is not disabled
    } = props

    const currentMode = defaultNavigatorMode ?? DateNavigatorMode.WEEK;
    const [notificationMenuActive, setNotificationMenuActive] = useState();

    return (
        <>
            <DateNavigatorRoot $isOnMentorSchedule={isOnMentorSchedule}>
                <SwitchWrapper>
                    <Switch
                        optionLeft={"Week"}
                        optionLeftMobile={"W"}
                        optionRight={"Maand"}
                        optionRightMobile={"M"}
                        defaultSwitchOption={(currentMode === DateNavigatorMode.WEEK) ? SwitchOption.LEFT : SwitchOption.RIGHT}
                        onAfterSwitch={(selectedOption) => {
                            setModeOnSwitch(selectedOption)
                        }}
                    />
                </SwitchWrapper>

                <DateNavigatorWrapper>
                    <NavigationButton
                        onClick={() => {
                            navigate(DateNavigatorDirection.BACKWARD)
                        }}
                        src={arrowLeft}
                        alt={"vorige knop"}
                    />
                    <Title>{getDateTitle()}</Title>
                    <NavigationButton
                        onClick={() => {
                            navigate(DateNavigatorDirection.FORWARD)
                        }}
                        src={arrowRight}
                        alt={"volgende knop"}
                    />
                </DateNavigatorWrapper>
                    <>
                        {!disableNotificationMenu &&
                            <NotificationMenuButton
                                onClick={() => {
                                    setNotificationMenuActive(true)
                                }}
                                src={notifications.filter((n) => {
                                    return !n.isRead
                                }).length > 0 ? notificationIconFilled : notificationIconOutline}
                            />
                        }
                    </>



            </DateNavigatorRoot>

            {notificationMenuActive && <NotificationMenu
                whatsNewObjects={whatsNewObjects}
                navigateToComment={navigateToComment}
                onClose={() => {
                    setNotificationMenuActive(false)
                }}
            />}
        </>
    );

    function navigate(direction) {
        onBeforeNavigation && onBeforeNavigation(momentObject)
        if (direction === DateNavigatorDirection.FORWARD) {
            goForward()
        } else if (direction === DateNavigatorDirection.BACKWARD) {
            goBackward()
        }
    }

    function goForward() {
        switch (currentMode) {
            case DateNavigatorMode.WEEK: {
                selectNextWeek()
                break;
            }
            case DateNavigatorMode.MONTH: {
                selectNextMonth()
                break;
            }
            default: selectNextWeek()
        }
    }

    function goBackward() {
        switch (currentMode) {
            case DateNavigatorMode.WEEK: {
                selectPreviousWeek()
                break;
            }
            case DateNavigatorMode.MONTH: {
                selectPreviousMonth()
                break;
            }
            default: selectPreviousWeek()
        }
    }

    function getDateTitle() {
        switch (currentMode) {
            case DateNavigatorMode.WEEK: {
                return `Week ${momentObject.week()}`
            }
            case DateNavigatorMode.MONTH: {
                const formattedDate = momentObject.format("MMM YYYY")
                return (formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)).replace(".", "");
            }
            default: return `Week ${momentObject.week()}`;
        }
    }

    function setModeOnSwitch(selectedSwitchOption) {
        switch (selectedSwitchOption) {
            case SwitchOption.LEFT: {
                onModeSwitch && onModeSwitch(DateNavigatorMode.WEEK)
                break;
            }
            case SwitchOption.RIGHT: {
                onModeSwitch && onModeSwitch(DateNavigatorMode.MONTH)
                break;
            }
            default: onModeSwitch && onModeSwitch(DateNavigatorMode.WEEK)
        }
    }

    function selectNextWeek() {
        onAfterNavigation(moment(momentObject.add(1, "week")))
    }

    function selectNextMonth() {
        onAfterNavigation(moment(momentObject.add(1, "month")))
    }

    function selectPreviousWeek() {
        onAfterNavigation(moment(momentObject.subtract(1, "week")))
    }

    function selectPreviousMonth() {
        onAfterNavigation(moment(momentObject.subtract(1, "month")))
    }
}

export const DateNavigatorHeight = 50;

const DateNavigatorRoot = styled.div`
    width: 100%;
    height: ${DateNavigatorHeight}px;
    align-items: center;
    z-index: ${({$isOnMentorSchedule}) => $isOnMentorSchedule ? 102 : 100};
    justify-content: center;
    background: ${purpleLighter};
    display: flex;
`;

const DateNavigatorWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 17px;
`;

const NotificationMenuButton = styled.img`
    z-index: 200;
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    right: 16px;
`;

const NavigationButton = styled.img`
    padding: 10px;
    &:hover {
        cursor: pointer;
    };
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    
    @media screen and ${device.mobileL} {
        padding: 0px;
    }
`;

const Title = styled.span`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 1.3125em;
    color: ${purple};
    
    @media screen and ${device.mobileL} {
        font-size: 14px;
    }
`;

const SwitchWrapper = styled.div`
    z-index: 100;
    cursor: pointer;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    left: 40px;
    
    @media screen and ${device.mobileL} {
        left: 20px;
    }
`;

export default DateNavigator;