import {ReactComponent as SpinnerIcon} from '../resources/images/spinner.svg';
import styled from "styled-components";
import {white} from "../Theme";
export const Spinner = ({color = white}) => {
    return (
        <StyledSpinnerIcon $color={color}/>
    );
}

const StyledSpinnerIcon = styled(SpinnerIcon)`
    animation: spin 1s linear infinite;
    width: 20px;
    height: 20px;

    & path {
        fill: ${({$color}) => $color};
    }
    
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;