import Select, {components} from 'react-select';
import React, {forwardRef} from 'react';
import styled from "styled-components";

// Overwrite react-select components
const Control = ({children, ...props}) => (
    <components.Control {...props}>
        {children}
    </components.Control>
);

const Input = ({...props}) => (
    <components.Input {...props} aria-activedescendant={undefined} />
);

const IndicatorsContainer = ({...props}) => (
    <components.IndicatorsContainer {...props} />
);

const ClearIndicator = () => null;
const DropdownIndicator = () => null;
const IndicatorSeparator = () => null;
const MultiValueRemove = () => null;
const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    const selectedValue = getValue()?.[0];

    return (
        <components.ValueContainer {...props}>
            {selectedValue?.icon && (
                <IconWrapper>{selectedValue.icon}</IconWrapper>
            )}
            {children}
        </components.ValueContainer>
    );
};

export const BaseSelect = forwardRef(function BaseSelect(props, ref) {
    const {options, placeholder, onChange, defaultValue, disabled, styles, ...otherProps} = props;

    return (
        <Select
            ref={ref}
            options={options}
            placeholder={placeholder}
            isMulti={false}
            isDisabled={disabled}
            isSearchable={false}
            isClearable={false}
            closeMenuOnSelect={true}
            noOptionsMessage={() => "Geen opties gevonden"}
            onChange={onChange}
            defaultValue={defaultValue}
            unstyled={true}
            components={{
                ...otherProps?.customComponents,
                Control,
                Input,
                DropdownIndicator,
                IndicatorsContainer,
                IndicatorSeparator,
                MultiValueRemove,
                ClearIndicator,
                ValueContainer
            }}
            styles={styles}
            {...otherProps}
        />
    );
})

const IconWrapper = styled.div`
    width: 20px;
    height: 20px;
`;