import './App.scss';
import LoginScreen from "./screens/login/LoginScreen";
import ScheduleScreen from "./screens/schedule/ScheduleScreen";
import ExamOverviewScreen from "./screens/examoverview/ExamOverviewScreen";
import {BrowserRouter, Switch, useLocation} from "react-router-dom";
import NotFound from "./screens/NotFound";
import ExamScreen from "./screens/exam/ExamScreen";
import moment from "moment";
import HomeworkItemScreen from "./screens/homeworkitem/HomeworkItemScreen";
import ExecutiveFunctionOverviewScreen from "./screens/executivefunction/ExecutiveFunctionOverviewScreen";
import ExecutiveFunctionScreen from "./screens/executivefunction/ExecutiveFunctionScreen";
import MoreScreen from "./screens/more/MoreScreen";
import PlanOfActionQuestionnaire from "./screens/planofaction/PlanOfActionQuestionnaire";
import PlanOfActionOverview from "./screens/planofaction/PlanOfActionOverview";
import PlanOfActionScreen from "./screens/planofaction/PlanOfActionScreen";
import SetPasswordScreen from "./screens/login/SetPasswordScreen";
import ResetPasswordScreen from "./screens/login/ResetPasswordScreen";
import {Desktop, Mobile} from "./Theme";
import SideMenu from "./menu/SideMenu";
import BottomMenu from "./menu/BottomMenu";
import React, {useEffect, useState} from "react";
import styled, {StyleSheetManager} from "styled-components";
import {device} from "./constants/breakpoints";
import AppStorage, {StorageKey} from "./util/AppStorage";
import TopMenu from "./menu/TopMenu";
import MentorSchedulePupilsScreen from "./screens/mentorschedule/MentorSchedulePupilsScreen";
import PrivateRoute from "./util/PrivateRoute";
import {useIsUserAuthenticated} from "./components/hooks/useIsUserAuthenticated";
import SentryRoute from './util/SentryRoute';
import isPropValid from '@emotion/is-prop-valid';
import {AccessGate} from './layouts/AccessGate';
import TeacherScheduleScreen from './screens/teacherschedule/TeacherScheduleScreen';
import MentorPlanOfActionScreen from './screens/planofaction/MentorPlanOfActionScreen';

function App() {

    const userIsAuthenticated = useIsUserAuthenticated();

    /**
     * Mobile viewport fix
     * @type {number}
     */
    function setViewPortMobile() {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    window.addEventListener('resize', setViewPortMobile);
    setViewPortMobile();

    // set locale
    moment.locale('nl')

    return (
        <StyleSheetManager shouldForwardProp={shouldForwardProp} enableVendorPrefixes>
            <AccessGate userIsAuthenticated={userIsAuthenticated}>
                <AppWrapper>
                    <BrowserRouter>

                        {userIsAuthenticated &&
                            <>
                                <Desktop>
                                    <SideMenu/>
                                </Desktop>

                                <Mobile>
                                    <TopMenu/>
                                </Mobile>
                            </>
                        }

                        <Switch>

                            {publicRoutes.map(route => {
                                return (
                                    <SentryRoute
                                        key={route.path}
                                        path={route.path}
                                        exact={route.exact}
                                        component={route.component}
                                    />
                                )
                            })}

                            {privateRoutes.map(route => {
                                return (
                                    <PrivateRoute
                                        key={route.path}
                                        userIsAuthenticated={userIsAuthenticated}
                                        path={route.path}
                                        exact={route.exact}
                                        component={route.component}
                                    />
                                )
                            })}
                        </Switch>

                        <Mobile>
                            <BottomMenu/>
                        </Mobile>
                    </BrowserRouter>
                </AppWrapper>
            </AccessGate>
        </StyleSheetManager>
    );

    // This implements the default behavior from styled-components v5
    function shouldForwardProp(propName, target) {
        if (typeof target === "string") {
            // For HTML elements, forward the prop if it is a valid HTML attribute
            return isPropValid(propName);
        }
        // For other elements, forward all props
        return true;
    }
}

export function isInReadOnlyMode() {
    return AppStorage.get(StorageKey.PUPIL_ID) !== AppStorage.get(StorageKey.USER_ID)
}

export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const publicRoutes = [
    {
        path: '/login',
        exact: true,
        component: LoginScreen
    },
    {
        path: '/verander-wachtwoord',
        exact: true,
        component: SetPasswordScreen
    },
    {
        path: '/herstel-wachtwoord',
        exact: true,
        component: ResetPasswordScreen
    }
]

export const privateRoutes = [
    {
        path: ['/', '/planagenda'],
        exact: true,
        component: ScheduleScreen
    },
    {
        path: '/executieve-functies/:id',
        exact: true,
        component: ExecutiveFunctionScreen
    },
    {
        path: '/executieve-functies',
        exact: true,
        component: ExecutiveFunctionOverviewScreen
    },
    {
        path: '/toets/:id?',
        exact: true,
        component: ExamScreen
    },
    {
        path: '/huiswerk/:id?',
        exact: true,
        component: HomeworkItemScreen
    },
    {
        path: '/toetsweek',
        exact: true,
        component: ExamOverviewScreen
    },
    {
        path: '/:mentor?/meer',
        exact: true,
        component: MoreScreen
    },
    {
        path: ['/plan-van-aanpak/:id/vragenlijst', '/ef-voortgang/:id/vragenlijst'],
        exact: true,
        component: PlanOfActionQuestionnaire
    },
    {
        path: ['/plan-van-aanpak/:id', '/ef-voortgang/:id'],
        exact: true,
        component: PlanOfActionScreen
    },
    // Should not be accessible anymore
    // {
    //     path: ['/mentor/ef-voortgang/:pupilId'],
    //     exact: true,
    //     component: MentorPlanOfActionScreen
    // },
    {
        path: ['/plan-van-aanpak', '/ef-voortgang'],
        exact: true,
        component: PlanOfActionOverview
    },
    {
        path: ['/dashboard', '/mentor/planagenda'],
        exact: true,
        component: MentorSchedulePupilsScreen
    },
    {
        path: ['/mentor/ef-voortgang'],
        exact: true,
        component: TeacherScheduleScreen
    },
    {
        path: '/',
        exact: false,
        component: NotFound
    },
];

export const RoutesWithoutNav = [
    "/login",
    "/verander-wachtwoord",
    "/herstel-wachtwoord",
    "/selecteer-leerling"
];

const AppWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    
    @media screen and ${device.tablet} {
      flex-direction: column;
    }
`;


export default App;