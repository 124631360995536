import {forwardRef} from "react";
import {BaseSelect} from "./BaseSelect";
import {black, nunito, purple, white} from "../../Theme";
import caretDownIcon from "../../resources/images/ic-caretDownBlack.svg";

export const FilterSelect = forwardRef(function FilterSelect(props, ref) {
    const {options, ...otherProps} = props;

    return (
        <BaseSelect
            options={options}
            styles={{
                container: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                }),
                control: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                    padding: "4px 40px 4px 17px",
                    borderRadius: "3px",
                    color: black,
                    fontFamily: nunito,
                    fontSize: "16px",
                    backgroundImage: `url(${caretDownIcon})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right 15px top 50%",
                    backgroundSize: "20px",
                    backgroundColor: white,
                    border: "1px solid #E2E2EB",
                    boxShadow: "0 2px 0 0 #E2E2EB",
                    textAlign: "left",
                    cursor: "pointer",
                }),
                placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: black,
                    opacity: ".3",
                    fontStyle: "italic",
                }),
                input: (baseStyles) => ({
                    ...baseStyles
                }),
                valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    gap: "10px",
                }),
                menu: (baseStyles, {options}) => ({
                    ...baseStyles,
                    background: options?.length > 0 ? "transparent" : white,
                    marginTop: "5px",
                    overflow: "hidden",
                    zIndex: "100",
                }),
                menuList: (baseStyles) => ({
                    ...baseStyles,
                    maxHeight: window.screen.height - 250,
                }),
                option: (baseStyles) => ({
                    ...baseStyles,
                    padding: "6px 12px",
                    borderRadius: "3px",
                    color: white,
                    fontFamily: nunito,
                    fontSize: "16px",
                    fontWeight: "700",
                    textAlign: "center",
                    background: purple,
                    boxShadow: "0 2px 0 0 #4D2F78",
                    marginBottom: "8px",
                    cursor: "pointer",
                }),
                noOptionsMessage: () => ({
                    color: black,
                    textAlign: "center",
                    fontFamily: nunito,
                    fontSize: "16px",
                    padding: "20px",
                }),
                singleValue: (baseStyles) => ({
                    ...baseStyles,
                }),
                clearIndicator: (baseStyles) => ({
                    ...baseStyles
                })
            }}
            {...otherProps}
        />
    )
})