import React, {useEffect, useState} from "react";
import BaseScreen from "../base/BaseScreen";
import {black, HeaderLogo, nunito, purple, purpleLight, red, SmallHeader, SmallHeaderTitle, sourceSansPro, white} from "../../Theme";
import Api from "../../util/Api";
import styled from "styled-components";
import backLogoBlack from "../../resources/images/backBlack.svg";
import backLogoWhite from "../../resources/images/backWhite.svg";
import {ReactComponent as ChevronRight} from '../../resources/images/chevron_right.svg';
import zoomLogo from "../../resources/images/zoom.svg";
import {darken} from 'polished';
import AppStorage, {StorageKey} from "../../util/AppStorage";
import showImagePopup from "../../popups/showImagePopup";
import {ContentWrapper, HeaderImage, HeaderImageWrapper} from "../more/MoreScreen";
import {device} from "../../constants/breakpoints";
import {useHistory, useParams} from "react-router-dom";
import {showNativeNavigation} from "../../AppInterface";
import {CheckItem} from "../../components/CheckItem";
import {FilterSelect} from "../../components/Select/FilterSelect";
import {useQuery} from "../../App";
import {PlanOfActionTabs} from "../../constants/enums";
import {Loader} from "../../components/Loader";

function ExecutiveFunctionScreen() {
    const history = useHistory();
    const [ef, setExecutiveFunction] = useState(history.location.state ? history.location.state.executiveFunction : null);
    const [efCheckedItems, setExecutiveFunctionCheckedItems] = useState([]);
    const [periodOptions, setPeriodOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [periodQuestionnaireFilled, setPeriodQuestionnaireFilled] = useState(false);
    const params = useParams();
    const query = useQuery();
    const periodId = query.get("periodId");

    useEffect(() => {
        showNativeNavigation();
        getExecutiveFunction();
        getPeriod();
        // eslint-disable-next-line
    }, [params]);

    useEffect(() => {
        if (!!ef) {
            getExecutiveFunctionCheckItems(periodId);
        }
        // eslint-disable-next-line
    }, [ef]);

    const content = (
        <EFScreen backgroundColor={ef ? ef.color : purpleLight}>
            {isLoading &&
                <Loader/>
            }

            <SmallHeaderSpaced backgroundColor={ef ? darken(0.05, ef.color) : purple}>
                <HeaderLogo src={ef ? (ef.hasDarkText ? backLogoBlack : backLogoWhite) : backLogoWhite} onClick={() => {
                    history.goBack()
                }}/>
                <StyledSmallHeaderTitle color={ef ? (ef.hasDarkText ? black : white) : white}>Executieve Functies</StyledSmallHeaderTitle>
                {periodOptions?.length > 0 && <PeriodDropdown options={periodOptions} navigateToPeriod={(newPeriodId) => history.replace("/executieve-functies/" + ef.id + `?periodId=${newPeriodId}`)}/>}
            </SmallHeaderSpaced>
            <ContentWrapper>
                <HeaderImageWrapper>
                    <HeaderImage src={ef ? ef.imageUrl : undefined}/>
                    <ZoomImage src={zoomLogo} onClick={() => showImagePopup(ef.imageUrl)}/>
                </HeaderImageWrapper>
                {ef && <EFCardWrapper>
                    <EFCard>
                        <CardHeader>
                            <TitleHeader>
                                <Icon backgroundColor={ef ? ef.color : purple}
                                      color={ef ? (ef.hasDarkText ? black : white) : white}>{ef ? ef.sortOrder + 1 : ''}</Icon>
                                <Title>{ef ? ef.title : 'Executieve Functie'}</Title>
                            </TitleHeader>
                            {periodQuestionnaireFilled === true &&
                                <BackTo onClick={() => {
                                    history.push(`/ef-voortgang/${periodId}?activeTab=${PlanOfActionTabs.PLANOFACTION}`)
                                }}>
                                    Naar plan van aanpak <StyledChevronRight/>
                                </BackTo>
                            }
                        </CardHeader>
                        <Subtitle>{ef && ef.subtitle}</Subtitle>
                        <CheckLists>
                            {ef && ef.checkLists.map((checkList) => {
                                return (
                                    <CheckList key={checkList.id}>
                                        <CheckListTitle>{checkList.title}</CheckListTitle>
                                        {checkList.checkItems.map((checkItem) => {
                                            const isChecked = !!efCheckedItems.find((item) => {
                                                return item.id === checkItem.id
                                            })
                                            return <CheckItem key={checkItem.id} checkItem={checkItem} isChecked={isChecked} updateCheckedItems={getExecutiveFunctionCheckItems} executiveFunction={ef} periodId={periodId}/>
                                        })}
                                    </CheckList>
                                )
                            })}
                        </CheckLists>
                    </EFCard>
                </EFCardWrapper>}
            </ContentWrapper>
        </EFScreen>
    );

    return (
        <BaseScreen
            activeMenuItem={"Executieve Functies"}
            content={content}
        />
    )

    function getExecutiveFunctionCheckItems(periodId) {
        setIsLoading(true)
        if (periodId === null) periodId = periodOptions[0].value

        const config = {
            params: {
                'filter[executiveFunctionId]': decodeURIComponent(params.id).trim(),
                'filter[periodId]': periodId
            }
        }

        Api.get('pupils/' + AppStorage.get(StorageKey.PUPIL_ID) + "/checkItems", onSuccess, null, null, null, config);

        function onSuccess(response) {
            setExecutiveFunctionCheckedItems(response.data)
            setIsLoading(false)
        }
    }

    function getExecutiveFunction() {
        Api.get(`executiveFunctions/${decodeURIComponent(params.id).trim()}`, (response) => {
            setExecutiveFunction(response.data)
            setPeriodOptions(response.data.periodFilterOptions)
        });
    }

    function getPeriod() {
        const config = {
            params: {
                'context[personId]': AppStorage.get(StorageKey.PUPIL_ID),
            }
        }
        // Need to get period to check if questionnaire is filled
        Api.get(`periods/${periodId}`, (response) => {
            setPeriodQuestionnaireFilled(response.data.scoresPerExecutiveFunction.filter(ef => ef.totalScore == null).length === 0)
        }, null, null, null, config);
    }
}

function PeriodDropdown({options, navigateToPeriod}) {
    const query = useQuery();
    const periodId = query.get("periodId")

    return (
        <FilterSelectWrapper>
            <FilterSelect
                defaultValue={options.find(x => x.value === periodId)}
                options={options}
                onChange={(selectedOption) => {
                    navigateToPeriod(selectedOption.value)
                }}
            />
        </FilterSelectWrapper>
    )
}

export const ZoomImage = styled.img`
    src: ${props => props.src};
    cursor: pointer;
    width: 35px;
    height: 35px;
    padding: 8px;
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.6);
    border-radius: 3px;
    position: absolute;
    top: 55px;
    right: 15px;
    z-index: 10;

    @media screen and ${device.mobileL} {
        top: 15px;
    }
`;

export const CheckLists = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 30px;
`;

const CheckListTitle = styled.div`
    font-family: ${sourceSansPro};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: ${red};
`;

const EFCardWrapper = styled.div`
    max-width: 679px;
    margin: auto;
    padding: 0 20px;
    box-sizing: content-box;
`;

const EFCard = styled.div`
    width: 100%;
    background: ${white};
    padding: 35px 40px;
    z-index: 10;
    position: relative;
    top: -110px;
    border-radius: 3px;
    border: 1px solid rgba(86, 89, 146, 0.1);
    box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);

    @media screen and ${device.mobileL} {
        top: -40px;
        padding: 15px 20px;
    }
`;

const CheckList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Subtitle = styled.div`
    font-family: ${sourceSansPro};
    opacity: 0.7;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    margin-top: 17px;
`;

const CardHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

const TitleHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
`;

const Icon = styled.div`
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
`;

const Title = styled.div`
    font-family: ${nunito};
    color: black;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
`

const SmallHeaderSpaced = styled(SmallHeader)`
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    gap: 10px;

    @media screen and ${device.mobileL} {
        padding: 10px;
    }
`;

const StyledSmallHeaderTitle = styled(SmallHeaderTitle)`
    text-wrap: nowrap;
`;

const FilterSelectWrapper = styled.div`
    padding: 10px 0;
    max-width: 130px;
    right: 40px;
    flex-shrink: 1;
`;

const EFScreen = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    background: ${props => props.backgroundColor}
`;

const BackTo = styled.button`
    display: flex;
    gap: 6px;
    color: ${purple};
    border: none;
    font-family: ${nunito};
    font-weight: 700;
    font-size: 16px;
    background: none;
    justify-content: center;
    align-items: center;
    padding: 6px 0;

    &:hover {
        cursor: pointer;
    }
`;

const StyledChevronRight = styled(ChevronRight)`
    width: 14px;
    height: 14px;
`;

export default ExecutiveFunctionScreen;
