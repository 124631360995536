import {forwardRef} from "react";
import {BaseSelect} from "./BaseSelect";
import {purpleDark, purpleLight, sourceSansPro, violet, violetDark, white,} from "../../Theme";
import caretDownIcon from "../../resources/images/ic-caretDown.svg";
import {OptionWithDivider} from "./OptionWithDivider";

export const TopMenuSelect = forwardRef(function TopMenuSelect(props, ref) {
    const {options, disabled, ...otherProps} = props;

    return (
        <BaseSelect
            options={options}
            disabled={disabled}
            customComponents={{Option: OptionWithDivider}}
            styles={{
                container: (baseStyles) => ({
                    ...baseStyles,
                    minWidth: 0,
                    marginBottom: "3px",
                    justifyContent: "center",

                }),
                control: (baseStyles) => ({
                    ...baseStyles,
                    minHeight: 0,
                    height: "100%",
                    minWidth: 0,
                    maxWidth: "100%",
                    padding: "4px 40px 4px 4px",
                    borderRadius: "3px",
                    color: white,
                    fontFamily: sourceSansPro,
                    fontSize: "16px",
                    backgroundImage: !disabled ? `url(${caretDownIcon})` : "none",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right 15px top 50%",
                    cursor: "pointer",
                    border: "none",
                    backgroundColor: violetDark,
                    boxShadow: "0 2px rgba(226, 226, 235, 0.2)",
                }),
                placeholder: (baseStyles) => ({
                    ...baseStyles,
                }),
                input: (baseStyles) => ({
                    ...baseStyles,
                }),
                valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    gap: "10px",
                    display: "flex",
                    flexWrap: "none",

                }),
                menu: (baseStyles, {options}) => ({
                    ...baseStyles,
                    background: violetDark,
                    overflow: "hidden",
                    zIndex: "100",
                    borderRadius: "3px",
                    color: white,
                    fontFamily: sourceSansPro,
                    fontSize: "16px",
                    transform: `translate(0, 8px)`,
                }),
                menuList: (baseStyles) => ({
                    ...baseStyles,

                    "::-webkit-scrollbar": {
                        width: "4px",
                        height: "0px",
                    },
                    "::-webkit-scrollbar-track": {
                        background: purpleDark
                    },
                    "::-webkit-scrollbar-thumb": {
                        background: violet
                    },
                    "::-webkit-scrollbar-thumb:hover": {
                        background: purpleLight
                    }
                }),
                option: (baseStyles) => ({
                    ...baseStyles,
                    cursor: "pointer",
                    padding: "12px 0"
                }),
                noOptionsMessage: (baseStyles) => ({
                    ...baseStyles,
                    padding: "12px 12px",
                    color: white,
                    fontFamily: sourceSansPro,
                    fontSize: "16px",
                    fontWeight: "700",
                    textAlign: "center",
                    boxShadow: "0 2px 0 0 #4D2F78",
                    cursor: "pointer",
                }),
                singleValue: (baseStyles) => ({
                    ...baseStyles,
                    maxWidth: "100%",
                    color: "white",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }),
                clearIndicator: (baseStyles) => ({
                    ...baseStyles
                }),
            }}
            {...otherProps}
        />
    )
})