import React, {useEffect, useState} from "react";
import styled, {ThemeProvider} from "styled-components";
import {Desktop, Mobile, nunito, purpleDark, red, whiteTransparent} from "../Theme";
import calenderLogo from "../resources/images/calendar.svg";
import chartLogo from "../resources/images/chart.svg";
import bulbLogo from "../resources/images/bulb.svg";
import scienceLogo from "../resources/images/science.svg";
import infoLogo from "../resources/images/info.svg";
import logoutLogo from "../resources/images/logout.svg";
import opnijLogo from "../resources/images/logo.svg";
import menuBackground from "../resources/images/menuBackground.svg";
import AppStorage, {StorageKey} from "../util/AppStorage";
import {Link, useHistory, useLocation} from "react-router-dom";
import {RoutesWithoutNav} from "../App";
import {UserType} from "../constants/enums";
import {useGlobalState} from "../util/GlobalState";
import {ReactComponent as PremiumIcon} from '../resources/images/premium.svg';
import {ReactComponent as PupilIcon} from '../resources/images/pupil_profile.svg';
import {ReactComponent as GradeIcon} from '../resources/images/grade.svg';
import {checkRequiredFeatureFlags} from '../util/Helpers';
import {FeatureFlags} from '../constants/featureFlags';
import {useMenuDataContext} from '../contexts/MenuDataContext';
import Api from "../util/Api";
import {SideMenuSelect} from "../components/Select/SideMenuSelect";
import showBasicPopup from "../popups/showBasicPopup";
import {TopMenuSelect} from "../components/Select/TopMenuSelect";

function SideMenu() {
    const {featureFlags, setPupilOptions} = useMenuDataContext();
    const history = useHistory();
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState(location.pathname);
    const [isMentorView,] = useGlobalState('isMentorView', false);
    const [isVisible, setVisible] = useState(false);
    const [selectedGrade] = useGlobalState("selectedGrade")


    useEffect(() => {
        const userType = AppStorage.get(StorageKey.USER_TYPE);
        const pupilID = AppStorage.get(StorageKey.PUPIL_ID);
        if (userType === UserType.MENTOR && pupilID === null && !location.pathname.startsWith("/mentor")) {
            history.push("/mentor/ef-voortgang");
        }
        if (userType === UserType.PUPIL && location.pathname.startsWith("/mentor")) {
            history.push("/ef-voortgang");
        }
    })

    useEffect(() => {
        if (RoutesWithoutNav.includes(location.pathname)) {
            setVisible(false);
        } else {
            setVisible(true);
        }
        setCurrentLocation(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        if (!AppStorage.get(StorageKey.GRADE_ID)) {
            AppStorage.set(StorageKey.GRADE_ID, selectedGrade)
        }

        if (AppStorage.get(StorageKey.USER_TYPE) !== "PUPIL" && AppStorage.get(StorageKey.GRADE_ID)) {
            getStudentsFromGrade(AppStorage.get(StorageKey.GRADE_ID));
        }
        //eslint-disable-next-line
    }, [selectedGrade]);

    function getStudentsFromGrade(grade) {
        Api.get("/grades/" + grade + "/pupils", (response) => {
            const data = response.data
            const defaultOption = {
                value: null,
                label: "Alle leerlingen",
                icon: <StyledPupilIcon/>
            };
            const options = [
                defaultOption,
                ...data.map((pupil) => ({
                    value: pupil.id,
                    label: pupil.title,
                    icon: <StyledPupilIcon/>
                }))
            ]
            setPupilOptions(options)
        }, null, null, () => {
            showBasicPopup("Ophalen van leerlingen  mislukt!", "Er is iets mis gegaan met het ophalen van de leerlingen. Probeer het zometeen nog een keer.", "OK")
        }, {})
    }

    return (
        <ThemeProvider theme={defaultSidebarTheme}>
            <SideMenuWrapper $visible={isVisible}>
                <MenuBackground src={menuBackground}/>
                <MenuLogo src={opnijLogo}/>
                {AppStorage.get(StorageKey.USER_TYPE) !== UserType.PUPIL && (
                    <MentorMenuTitle>Mentor dashboard</MentorMenuTitle>
                )}
                <PupilInfo isMentorView={isMentorView}/>
                <Divider $isBig={true}/>
                {isMentorView ? (
                    <MenuItemList>
                        <MenuItem
                            src={chartLogo}
                            text={"EF in beeld"}
                            isActive={currentLocation.startsWith("/mentor/ef-voortgang")}
                            to={"/mentor/ef-voortgang"}
                            location={location}
                        />
                        <Divider/>
                        <MenuItem
                            src={calenderLogo}
                            text={"Planagenda"}
                            isActive={currentLocation === "/mentor/planagenda"}
                            to={"/mentor/planagenda"}
                            location={location}
                            showPremiumIcon={!checkRequiredFeatureFlags(featureFlags, FeatureFlags.FEATURE_SCHEDULE)}
                        />
                        <MenuItem
                            src={infoLogo}
                            text="Handleiding"
                            isActive={currentLocation === "/mentor/meer"}
                            to="/mentor/meer"
                            location={location}
                        />
                    </MenuItemList>
                ) : (
                    <MenuItemList>
                        <MenuItem
                            src={chartLogo}
                            text={"EF in beeld"}
                            isActive={currentLocation.includes("/plan-van-aanpak") || currentLocation.startsWith("/ef-voortgang")}
                            to={"/ef-voortgang"}
                            location={location}
                        />
                        <MenuItem
                            src={bulbLogo}
                            text={"Executieve Functies"}
                            isActive={currentLocation === "/executieve-functies"}
                            to={"/executieve-functies"}
                            location={location}
                        />
                        <Divider/>
                        <MenuItem
                            src={calenderLogo}
                            text={"Planagenda"}
                            isActive={currentLocation === "/" || currentLocation === "/planagenda"}
                            to={"/planagenda"}
                            location={location}
                            showPremiumIcon={!checkRequiredFeatureFlags(featureFlags, FeatureFlags.FEATURE_SCHEDULE)}
                        />
                        <MenuItem
                            src={scienceLogo}
                            text={"Toetsweek"}
                            isActive={currentLocation === "/toetsweek"}
                            to={"/toetsweek"}
                            location={location}
                            showPremiumIcon={!checkRequiredFeatureFlags(featureFlags, FeatureFlags.FEATURE_SCHEDULE)}
                        />
                        <Divider/>
                        <MenuItem
                            src={infoLogo}
                            text="Handleiding"
                            isActive={currentLocation === "/meer"}
                            to="/meer"
                            location={location}
                        />
                    </MenuItemList>
                )}

                <MenuItemList>
                    <LogOutButton src={logoutLogo} text={"Uitloggen"} to={"/login"} onClick={() => {
                        logoutAndGoToLogin(history)
                    }}/>
                </MenuItemList>
            </SideMenuWrapper>
        </ThemeProvider>
    )
}

export function loginAndContinueToNextScreen(data, history) {
    AppStorage.set(StorageKey.API_ACCESS_TOKEN, data.accessToken)
    AppStorage.set(StorageKey.USER_ID, data.id)
    AppStorage.set(StorageKey.FINISHED_ONBOARDING, data.hasFinishedOnboarding)
    AppStorage.set(StorageKey.USER_TYPE, data.type)
    if (data.type === UserType.PUPIL) {
        //login in as pupil
        AppStorage.set(StorageKey.PUPIL_ID, data.id)
    }

    const userType = AppStorage.get(StorageKey.USER_TYPE)
    if (userType === UserType.PUPIL) {
        history.replace("/ef-voortgang");
    } else if (userType === UserType.MENTOR) {
        history.replace("/mentor/ef-voortgang");
    }
}

export function logoutAndGoToLogin(history) {
    AppStorage.remove(StorageKey.USER_ID)
    AppStorage.remove(StorageKey.USER_TYPE)
    AppStorage.remove(StorageKey.API_ACCESS_TOKEN)
    AppStorage.remove(StorageKey.FINISHED_ONBOARDING)
    AppStorage.remove(StorageKey.PUPIL_ID)
    AppStorage.remove(StorageKey.PUPIL_TITLE)
    AppStorage.remove(StorageKey.GRADE_ID)
    history.replace("/login")
}

function PupilInfo({isMentorView}) {
    const {gradeOptions, pupilOptions} = useMenuDataContext();

    return (
        <PupilInfoWrapper>
            <PupilInfoBar type={"grade"} isMentorView={isMentorView} options={gradeOptions} icon={<StyledGradeIcon/>} minHeight={"36px"}/>
            <PupilInfoBar type={"pupil"} isMentorView={isMentorView} options={pupilOptions} icon={<StyledPupilIcon/>} minHeight={"36px"}/>
        </PupilInfoWrapper>
    )
}

export function PupilInfoBar(props) {
    const {type, isMentorView, options, minHeight, icon, ...otherProps} = props;
    const history = useHistory();

    const [, setSelectedGrade] = useGlobalState("selectedGrade")
    const [, setSelectedPupil] = useGlobalState("selectedPupil")

    const handleSelectOption = (selectedOption) => {
        switch (type) {
            case "pupil":
                setSelectedPupil(selectedOption.value);
                AppStorage.set(StorageKey.PUPIL_ID, selectedOption.value)
                history.push("/ef-voortgang")
                break;
            case "grade":
                setSelectedGrade(selectedOption.value);
                AppStorage.set(StorageKey.GRADE_ID, selectedOption.value)
                AppStorage.set(StorageKey.PUPIL_ID, null)
                break;
            default:
                break;
        }
    }

    const getValue = () => {
        if (options) {
            if (type === "pupil") {
                const pupil = AppStorage.get(StorageKey.PUPIL_ID)
                return {"value": pupil, "label": options.find(item => item.value === pupil)?.label, "icon": <StyledPupilIcon/>}
            } else {
                const grade = AppStorage.get(StorageKey.GRADE_ID)
                return {"value": grade, "label": options.find(item => item.value === grade)?.label, "icon": <StyledGradeIcon/>}
            }
        }
    }

    return (
        <>
            <Desktop>
                <SideMenuSelect
                    value={getValue()}
                    placeholder={icon}
                    disabled={AppStorage.get(StorageKey.USER_TYPE) === UserType.PUPIL}
                    options={options}
                    onChange={(selectedOption) => handleSelectOption(selectedOption)}
                />
            </Desktop>

            <Mobile>
                <TopMenuSelect
                    value={getValue()}
                    placeholder={icon}
                    disabled={AppStorage.get(StorageKey.USER_TYPE) === UserType.PUPIL}
                    options={options}
                    onChange={(selectedOption) => handleSelectOption(selectedOption)}
                />
            </Mobile>
        </>

    )
}

function MenuItem({showPremiumIcon, ...props}) {
    return (
        <MenuItemWrapper to={props.to} $isActive={props.isActive}>
            <MenuItemLogo src={props.src}/>
            <MenuItemTextWrapper>
                <MenuItemText> {props.text}</MenuItemText>
                {showPremiumIcon && <StyledPremiumIcon/>}
            </MenuItemTextWrapper>
        </MenuItemWrapper>
    );
}

function LogOutButton(props) {
    return (
        <LogOutButtonWrapper onClick={props.onClick}>
            <MenuItemLogo src={props.src}/>
            <MenuItemText> {props.text}</MenuItemText>
        </LogOutButtonWrapper>
    );
}

const defaultSidebarTheme = {
    outerWidth: "251px",
    innerWidth: "221px"
}

const SideMenuWrapper = styled.div`
    background: ${purpleDark};
    width: ${({theme}) => theme.outerWidth};
    height: 100%;
    display: ${props => props.$visible ? "flex" : "none"};
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 53px;
    flex-shrink: 0;
    padding-bottom: 39px;
    overflow-y: auto;
    overflow-x: hidden;

    div:last-of-type {
        margin-top: auto;
    }
`;


const MenuItemList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    z-index: 10;
`;

const MenuLogo = styled.img`
    height: 62px;
    width: 118px;
    color: white;
    margin-bottom: 30px;
`;

const MenuBackground = styled.img`
    filter: opacity(7%);
    position: absolute;
    left: 0;
    bottom: 0;
    height: 179px;
    z-index: 1;
    width: 188px;
    color: white;
`;

const MentorMenuTitle = styled.h2`
    font-family: nunito;
    font-size: 21px;
    color: white;
    text-align: center;
    margin: 20px 0;
`;

const Divider = styled.hr`
    width: 100%;
    border-color: ${whiteTransparent};
    margin: ${props => props.$isBig ? "22px 0" : "12px 0"};
`;

const PupilInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
`;

const MenuItemWrapper = styled(Link)`
    text-decoration: none;
    background: ${props => props.$isActive ? red : undefined};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.$backToMentorSchedule ? "10px" : "25px"};
    height: 42px;
    width: ${({theme}) => theme.innerWidth};
    padding-left: 13px;
    padding-right: 13px;
    padding-bottom: ${props => props.$paddingBottom};
    text-overflow: ellipsis;
`;

const LogOutButtonWrapper = styled.div`
    text-decoration: none;
    background: ${props => props.$isActive ? red : undefined};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    height: 42px;
    width: ${({theme}) => theme.innerWidth};
    padding-left: 13px;
    padding-right: 13px;
    cursor: pointer;
`;

const MenuItemLogo = styled.img`
    color: white;
    display: block;
    height: ${props => props.$size ? props.$size : "24px"};
    width: ${props => props.$size ? props.$size : "24px"};
`;

const MenuItemTextWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const MenuItemText = styled.h1`
    color: white;
    font-family: ${nunito};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
`;

const StyledPremiumIcon = styled(PremiumIcon)`
    width: 24px;
    height: 24px;
    display: block;
    flex-shrink: 0;
`;

export const StyledPupilIcon = styled(PupilIcon)`
    width: 20px;
    height: 20px;
    display: block;
    flex-shrink: 0;
`;

export const StyledGradeIcon = styled(GradeIcon)`
    width: 20px;
    height: 20px;
    display: block;
    flex-shrink: 0;
`;

export default SideMenu;