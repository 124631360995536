import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import logo from "../../resources/images/logo.svg"
import loginDecoration from "../../resources/images/loginDecoration.png"
import {Button, Input, nunito, purple, purpleDark, purpleLighter, purpleLightest, red, white} from "../../Theme";
import Api from "../../util/Api";
import {device} from "../../constants/breakpoints";
import styled from "styled-components";
import AppStorage, {StorageKey} from "../../util/AppStorage";
import showBasicPopup from "../../popups/showBasicPopup";
import {useHistory} from "react-router-dom";
import checkLogoWhite from "../../resources/images/checkWhite.svg";
import {UserType} from "../../constants/enums";
import {loginAndContinueToNextScreen} from "../../menu/SideMenu";
import {hideNativeNavigation, setHistoryRef} from "../../AppInterface";
import {useGlobalState} from "../../util/GlobalState";
import {CheckItemCheck, CheckItemCheckWrapper, CheckItemRoot, CheckItemTitle} from "../../components/CheckItem";

function LoginScreen(props) {
    const history = useHistory();
    const {register, handleSubmit} = useForm();
    const [checkedPrivacy, setCheckedPrivacy] = useState(false);
    const [,setGradeOptions] = useGlobalState("gradeOptions", null);
    const [,setPupilOptions] = useGlobalState("pupilOptions", null);
    const [userIsAuthenticated] = useGlobalState("isUserAuthenticated",false)

    useEffect(() => {
        hideNativeNavigation();
        setHistoryRef(history);
    })

    useEffect(() => {
        if (userIsAuthenticated) {
            const userType = AppStorage.get(StorageKey.USER_TYPE)
            if (userType === UserType.PUPIL) {
                history.replace("/ef-voortgang");
            } else if (userType === UserType.MENTOR) {
                history.replace("/mentor/ef-voortgang");
            }
        }
        // eslint-disable-next-line
    },[userIsAuthenticated])

    function onSubmit(data) {
        if(!data.email) {
            showBasicPopup("Inloggen mislukt!", "Vul je e-mailadres in om in te loggen", "OK")
            return;
        }

        if(!data.password) {
            showBasicPopup("Inloggen mislukt!", "Vul je wachtwoord in om in te loggen", "OK")
            return;
        }

        if(!checkedPrivacy) {
            showBasicPopup("Privacyvoorwaarden", "Ga akkoord met de privacyvoorwaarden om in te loggen", "OK")
            return;
        }

        Api.post("/login", {
            email: data.email,
            password: data.password
        }, (response) => {
            setGradeOptions(null)
            setPupilOptions(null)
            loginAndContinueToNextScreen(response.data, history)
        }, null, null, () => {
            showBasicPopup("Inloggen mislukt!", "Verkeerde combinatie van e-mail en wachtwoord", "OK")
        })
    }

    return (
        <LoginScreenRoot>
            <LoginWrapper>
                <LoginContainer>

                    <LoginLogo src={logo} alt="Opnij-logo"/>
                    <LoginFormContainer onSubmit={handleSubmit(onSubmit)}>

                        <LoginFormInputContainer>
                            <LoginInputLabel htmlFor="email">E-mailadres</LoginInputLabel>
                            <Input id={"email"} type="email" {...register("email")}/>
                        </LoginFormInputContainer>

                        <LoginFormInputContainer>
                            <LoginInputLabel htmlFor="password">Wachtwoord</LoginInputLabel>
                            <Input id={"password"} type="password" {...register("password")}/>
                        </LoginFormInputContainer>

                        <ResetPasswordLink onClick={() => {
                            history.push('/herstel-wachtwoord')
                        }}>Wachtwoord resetten</ResetPasswordLink>
                        <PrivacyWrapper onClick={() => setCheckedPrivacy(!checkedPrivacy)}>
                            <CheckItemCheckWrapper secondaryColor={purple}>
                                {checkedPrivacy && <CheckItemCheck src={checkLogoWhite}/>}
                            </CheckItemCheckWrapper>
                            <PrivacyLabel>
                                Ik heb het <PrivacyLink href={"https://onderwijscentrumopnij.nl/privacybeleid/"} target={"_blank"} rel="noreferrer">privacybeleid</PrivacyLink> gelezen en ga hiermee akkoord
                            </PrivacyLabel>
                        </PrivacyWrapper>
                        <LoginButtonContainer>
                            <Button>Inloggen</Button>
                        </LoginButtonContainer>
                    </LoginFormContainer>

                    <LoginDecoration src={loginDecoration} alt=""/>
                </LoginContainer>
            </LoginWrapper>
        </LoginScreenRoot>
    )
}

export const PrivacyLink = styled.a`
  font-family: ${nunito};
  text-decoration: underline;
  color: ${white};
  &:hover{
    cursor: pointer;
    color: #B998B9;
  }
`;

const PrivacyLabel = styled(CheckItemTitle)`
    font-family: ${nunito};
    font-size: 0.75em;
    text-decoration: none;
    color: ${white} !important;
    text-align: center;
    
    @media screen and ${device.tablet} {
         text-align: left;
    }
`;

const PrivacyWrapper = styled(CheckItemRoot)`
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
`;

export const LoginScreenRoot = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(${purpleLighter} 250px, ${purpleLightest} 250px);
    position: relative;
    overflow-x: hidden;
`;

export const LoginWrapper = styled.div`
    margin: 146px 17px 146px 17px;
    display: flex;
    justify-content: center;
    
    @media screen and ${device.mobileL} {
        margin: 55px 17px 55px 17px;
    }
`;

export const LoginContainer = styled.div`
    padding: 50px 124px 88px 124px;
    max-width: 648px;
    width: 100%;
    min-height: 574px;
    background: ${purpleDark};
    position: relative;
    
    @media screen and ${device.tablet} {
        padding: 50px 62px 88px 62px;
    }
    
    @media screen and ${device.mobileL} {
        padding: 50px 20px 20px 20px;
    }
`;

export const LoginLogo = styled.img`
    display: block;
    margin: 0 auto 60px auto;
    
    @media screen and ${device.mobileL} {
        max-width: 120px;
        margin: 0 auto 55px auto;
    }
`;

export const LoginFormContainer = styled.form`
    display: flex;
    flex-direction: column;
`;

export const LoginFormInputContainer = styled.div`
    position: relative;
`;

export const LoginExplanation = styled.p`
    font-family: ${nunito};
    font-weight: 600;
    color: ${white};
    margin: 0 0 30px 0;
    display: block;
`;

export const LoginInputLabel = styled.label`
    font-family: ${nunito};
    font-weight: 700;
    color: ${white};
    margin-bottom: 8px;
    display: block;
`;

export const LoginValidationError = styled.span`
    font-family: ${nunito};
    font-weight: 400;
    font-size: 0.75em;
    color: ${red};
    position: absolute;
    bottom: 12px;
    left: 0;
`;

export const ResetPasswordLink = styled.a`
    font-family: ${nunito};
    font-weight: 400;
    font-size: 0.75em;
    text-decoration: none;
    color: ${white};
    align-self: flex-end;
    margin-bottom: 15px;
    position: relative;
    top:-23px;
    &:hover{
        cursor:pointer;
    }
`;

export const LoginButtonContainer = styled.div`
    align-self: flex-end;
    z-index: 10;
    max-width: 170px;
    width: 100%;
`;

export const LoginDecoration = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    
    @media screen and ${device.mobileL} {
        max-width: 155px;
    }
`;

export default LoginScreen;
